import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { useVisible } from "react-hooks-visible";
import AnimatedText from "../reusable/AnimatedText";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import JoinTeamModal from "./JoinTeamModal";
import Image from "../img/you.png";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export default function JoinTeam({ i, delay=0 }) {

	const image = Image;
	const mobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
	const [ref, visible] = useVisible((vi) => vi > 0.9);
	const [display, setDisplay] = useState(false);
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);

	//Fire fade in animation only once
	useEffect(() => {
		if (visible && !display) {
			setDisplay(true);
		}
	}, [visible]);

	//Fetch modal content
	const { data } = useQuery(({
		queryKey: ['join-team'],
		queryFn: async () => {
			const response = await axios.get(process.env.REACT_APP_BACKEND+'/api/join-team');
			return response.data.data.attributes;
		}
	}));

	return (
		<>
			<Box
				ref={ref}
				sx={{
					opacity: display ? 1 : 0,
					translate: display ? "0" : "0 10vh",
					transition: "opacity 0.5s, translate 0.5s",
					transitionDelay: `${delay * 0.2}s`,
					"&:hover .MuiSvgIcon-root": {
						opacity: "1",
						marginLeft: "1rem",
					},
					cursor:'pointer'
				}}
				onClick={handleOpen}
			>
				<img
					src={image}
					alt="Description of the image"
					width={mobile? '100%' : '80%'}
					height={mobile? 250 : 450}
					style={{ objectFit: "cover" }}
					draggable={false}
				/>
				<AnimatedText
					variant="body1"
					component="p"
					sx={{ mt: "1rem" }}
				/>
				<Grid container justifyContent="center" alignItems="left">
					<Grid item xs={12}>
						<Typography
							variant="h6"
							component="h6"
							sx={{
								mt: "1rem",
								fontWeight: "bold",
								display: "flex",
								alignItems: "center",
								transition: "color 0.25s",
							}}
						>
							You?
							<ArrowForwardIcon
								fontSize="large"
								sx={{
									opacity: "0",
									transition: "opacity 0.25s, margin-left 0.25s",
								}}
							/>
						</Typography>
					</Grid>
				</Grid>
				<Typography variant="h6" component="h6">
					Join Our Team
				</Typography>
			</Box>
			<JoinTeamModal 
				i={i} 
				open={open} 
				setOpen={setOpen}
				content={data}
			/>
		</>
	);
}
