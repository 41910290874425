import * as React from "react";
import { Box, IconButton, useMediaQuery, Dialog, DialogContent, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@emotion/react";
import JoinTeamForm from "./JoinTeamForm";
import parse from 'html-react-parser';
import { useState } from "react";
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';

export default function AboutModal({ open, setOpen, content }) {

	const mobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

	const theme = useTheme();

	const [sent, setSent]=useState(false);

	return(
		<>
			<Dialog
				open={open}
				onClose={() => setOpen(false)}
				maxWidth="md"
				sx={{
					zIndex:9999
				}}
				PaperProps={{
					style: {
						backgroundColor: theme.palette.blue,
						boxShadow: "none",
					},
				}}
			>
				<DialogContent
					sx={{
						maxWidth: mobile ? "100vw" : "60vw",
						overflowY: "scroll",
						p: "2rem",
						marginTop: mobile ? "0vh" : "10px",
						overflowY: mobile ? "auto" : "unset",
					}}
					mobile={mobile}
				>
					<IconButton
						aria-label="Close"
						onClick={() => setOpen(false)}
						sx={{
							position: "absolute",
							top: mobile ? 10 : 1,
							right: mobile ? 6 : 1,
							zIndex: 2000000,
							color: "#fff",
						}}
					>
						<CloseIcon />
					</IconButton>
					{Boolean(content) &&
						<Box
							sx={{
								p:'0 1rem',
								color:'#fff !important'
							}}
						>
							<Typography variant='h3'
								sx={{
									color:theme=>theme.palette.yellow
								}}
							>
								{content.heading}
							</Typography>
							{parse(content.content)}
						</Box>
					}
					{sent?
						<Box
							sx={{
								mt:'2rem',
								width:'100%',
								display:'flex',
								flexDirection:'column',
								alignItems:'center'
							}}
						>
							<MarkEmailReadIcon
								sx={{
									fontSize:'4rem',
									mb:'1rem',
									color:theme=>theme.palette.yellow
								}}
							/>
							<Typography variant='h4' component='span'
								sx={{
									textAlign:'center',
									color:'#fff'
								}}
							>
								Thank you, we've got your enquiry and will be in touch to get things started!
							</Typography>
						</Box>
					:
						<JoinTeamForm 
							joinTeamForm={JoinTeamForm}
							setSent={setSent}
						/>
					}
				</DialogContent>
			</Dialog>
		</>
	);
}
