import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import DMLink from '../layout/DMLink';
import { useVisible } from 'react-hooks-visible';
import InsightPreview from '../insights/InsightPreview';

function HomeInsights({ data }) {

	const mobile = useMediaQuery(theme => theme.breakpoints.down('md'));

	const [insights, setInsights] = useState();

	//Fetch 3 most recent insights
	useEffect(() => {
		axios.get(
			process.env.REACT_APP_BACKEND+'/api/insights',
			{
				params:{
					'populate':'deep',
					'pagination[limit]':3,
					'sort':'publishedAt:desc'
				}
			}
		).then((response) => {
			setInsights(response.data.data);
		});
	}, []);

	//Fade in animation
	const [ref, visible] = useVisible((vi) => vi > (mobile ? 0.1 : 0.5));
	const [display, setDisplay] = useState(false);
	useEffect(() => {
		if (visible && !display) {
			setDisplay(true);
		}
	}, [visible]);

	return (
		<Box
			className="dm-section"
			data-color='#01153C'
			sx={{
				position: 'relative',
				background: '#FFF',
				p: mobile ? '4rem 0' : '8rem 0',
				color: theme => theme.palette.blue
			}}
		>
			<Container
				maxWidth='xl'
			>
				{insights &&
					<>
						<Grid container spacing={2}>
							<Grid item xs={12} md={6}>
								<Typography variant={mobile? 'h3' : 'h2'} component='h2'>
									Insights
								</Typography>
							</Grid>
							<Grid item xs={12} md={6}>
								<Typography variant='h6' component='p' sx={{ mb: '1rem' }}>
									{data?.insightsparagraph}
								</Typography>
								<DMLink
									text='View all insights'
									link='/insights'
								/>
							</Grid>
						</Grid>
						{insights &&
							<Grid container spacing={2} sx={{ mt: '4rem' }}
								ref={ref}
							>
								<Grid item xs={12} md={6}>
									<Box
										sx={{
											height: mobile? 400 : 816,
											opacity: display ? 1 : 0,
											transition: 'opacity 0.5s'
										}}
									>
										<InsightPreview insight={insights[0]}/>
									</Box>
								</Grid>
								<Grid item xs={12} md={6} container spacing={2}>
									<Grid item xs={12}>
										<Box
											sx={{
												opacity: display ? 1 : 0,
												transition: 'opacity 0.5s',
												transitionDelay: 0.25+'s',
											}}
										>
											<InsightPreview insight={insights[1]}/>
										</Box>
									</Grid>
									<Grid item xs={12}>
										<Box
											sx={{
												opacity: display ? 1 : 0,
												transition: 'opacity 0.5s',
												transitionDelay: 0.5+'s',
											}}
										>
											<InsightPreview insight={insights[2]}/>
										</Box>
									</Grid>
								</Grid>
							</Grid>
						}
					</>
				}
			</Container>
		</Box>
	);
}

export default HomeInsights;