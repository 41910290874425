import "./App.css";
import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import MainRoutes from "./routes/MainRoutes";
import { ParallaxProvider } from "react-scroll-parallax";
import { MetaProvider } from "./context/MetaContext";
import Cursor from "./reusable/Cursor";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
//import useCookies from "./analytics/useCookies";

const queryClient = new QueryClient();

function App() {

	//useCookies();

	const theme = createTheme({
		palette: {
			blue: "#01153C",
			yellow: "#FFCD00",
			white: "#FFFFFF",
		},
		typography: {
			fontFamily: "din-2014",
			h1: {
				fontSize: "8rem",
				//fontSize: "clamp(4.5rem, 7vw, 6.8rem)",
				fontWeight: "bold",
			},
			h2: {
				fontSize: "5rem",
				//fontSize: "clamp(2.5rem, 5vw, 4.8rem)",
				lineHeight: "5rem",
				//lineHeight: "clamp(2.5rem, 5vw, 4.8rem)",
				fontWeight: "bold",
			},
			h3: {
				fontSize: "2.6rem",
				//fontSize: "clamp(1.75rem, 3vw, 2.6rem)",
				fontWeight: "bold",
			},
			h4: {
				fontSize: "2rem",
				fontWeight:'bold'
				//fontSize: "clamp(1.35rem, 1.65vw, 2.15rem)",
			},
			//Call to action links
			h5: {
				fontSize: "1.6rem",
				//fontSize: 'clamp(1rem, 1.6vw, 1.6rem)',
				fontWeight: "bold",
			},
			h6: {
				fontSize: "1.4rem",
				//fontSize: 'clamp(1rem, 1.4vw, 1.4rem)',
			},
			p: {
				fontSize: "1.2rem",
				//fontSize: "clamp(1rem, 1.5vw, 1.2rem)",
			},
		},
		components: {
			MuiLink: {
				defaultProps: {
					underline: "none",
				},
			},
		},
	});

	return(
		<MetaProvider>
			<ThemeProvider theme={theme}>
				<QueryClientProvider client={queryClient}>
					<ParallaxProvider>
						<BrowserRouter>
							<MainRoutes />
						</BrowserRouter>
					</ParallaxProvider>
				</QueryClientProvider>
				<Cursor />
			</ThemeProvider>
		</MetaProvider>
	);
}

export default App;
