import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import About from '../about/About';
import Home from '../homepage/Home';
import Footer from '../layout/Footer';
import Header from '../layout/Header';
import Showcases from '../showcases/Showcases';
import Services from '../services/Services';
import SingleShowcase from '../showcases/SingleShowcase';
import Insights from '../insights/Insights';
import SingleInsight from '../insights/SingleInsight';
import Contact from '../contact/Contact';
import SingleService from '../services/SingleService';
import usePageTracking from '../functions/usePageTracking';
import NotFound from '../reusable/NotFound';
import { Fade, Box } from '@mui/material';
import GenericPage from '../pages/GenericPage';
import axios from 'axios';

function Wrapper({ children }) {

	return(
		<Box 
			sx={{
				minHeight: '100vh',
			}}
		>
			<Fade
				in={true}
				timeout={1000}
			>
				<Box>
					{children}
					<Footer />
				</Box>
			</Fade>
		</Box>
	);
}

function Redirect({ to }){
	const navigate=useNavigate();
	useEffect(()=>{
		navigate(to);
	},[to]);
}

function MainRoutes() {

	usePageTracking();

	//Reset scroll on route change
    const location=useLocation();
    useEffect(()=>{
        window.scrollTo({top:0});
    },[location]);

	useEffect(() => {
		//Magnetise the elements
		const magnets = document.querySelectorAll('magnetised');
		if (!magnets) return;
		magnets.forEach(magnet => {
			// console.log(magnet)
			magnet.addEventListener('mousemove', (e) => {
				const rect = magnet.getBoundingClientRect();
				const x = e.clientX - rect.left - rect.width / 2;
				const y = e.clientY - rect.top - rect.height / 2;
				magnet.style.transform = `translate(${x * 0.5}px, ${y * 0.5}px)`;
			});

			magnet.addEventListener('mouseleave', () => {
				magnet.style.transform = 'translate(0px, 0px)';
			});
		});
	}, []);

	const [redirects, setRedirects]=useState();

    //Get redirects
    useEffect(()=>{
        axios.get(
            process.env.REACT_APP_BACKEND+'/api/redirects',
			{
				params:{
					'pagination[pageSize]':999999
				}
			}
        ).then((response)=>{
            //console.log(response)
            setRedirects(response.data.redirects);
        }).catch((error)=>{
            console.error(error);
        });
    },[]);

	return(
		<>
			<Header />
			{redirects &&
				<Routes>
					<Route path="/" element={<Wrapper><Home /></Wrapper>} />
					<Route path="/about" element={<Wrapper><About /></Wrapper>} />
					<Route path="/services" element={<Wrapper><Services /></Wrapper>} />
					<Route path="/services/:slug" element={<Wrapper><SingleService /></Wrapper>} />
					<Route path="/showcases" element={<Wrapper><Showcases /></Wrapper>} />
					<Route path="/showcases/:slug" element={<Wrapper><SingleShowcase /></Wrapper>} />
					<Route path="/insights" element={<Wrapper><Insights /></Wrapper>} />
					<Route path="/insights/:slug" element={<Wrapper><SingleInsight /></Wrapper>} />
					<Route path="/contact" element={<Wrapper><Contact /></Wrapper>} />
					{redirects?.map((redirect)=>{
						return(
							<Route path={redirect.from} element={<Redirect to={redirect.to}/>} key={redirect.id}/>
						);
					})}
					<Route path="/*" element={<Wrapper><GenericPage /></Wrapper>} />
					<Route path="/404" element={<Wrapper><NotFound /></Wrapper>} />
				</Routes>
			}
		</>
	);
}

export default MainRoutes;
