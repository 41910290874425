import { Container, Fade, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import InsightPreview from "./InsightPreview";
import InsightsList from "./InsightsList";
import parse from 'html-react-parser';
import { MetaContext } from "../context/MetaContext";
import { useQuery } from "@tanstack/react-query";

function Insights() {

    const mobile = useMediaQuery(theme => theme.breakpoints.down('md'));
	const meta=useContext(MetaContext);

    //Set html background color
    useEffect(()=>{
        document.body.style.backgroundColor='#fff'
    },[]);

    //Fetch showcase content
	const { data: pageData } = useQuery(({
		queryKey: ['page','insights'],//must match the menu slug
		queryFn: () => axios.get(`${process.env.REACT_APP_BACKEND}/api/insights-page?populate=deep`)
	}));

	useEffect(()=>{
		//console.log(pageData)
		if(pageData){
			meta.setMeta(pageData.data.data.attributes.seo);
		}
	},[pageData]);

    //Set header colour
	useEffect(()=>{
		window.dispatchEvent(new CustomEvent('headerColorChange',{detail:{color:'#01153C'}}));
	},[]);

    return (
        <Container maxWidth='xl'
            className="dm-section"
            data-color='#01153C'
        >
            <Box
                sx={{
                    minHeight:'100vh',
                    p:{
                        xs:'4rem 0',
                        md:'8rem 0'
                    },
                    color: theme => theme.palette.blue,
                }}
            >
                <Typography variant={mobile ? 'h2' : 'h1'} component='h1'
                    sx={{
                        mt: '4rem'
                    }}
                >
                    Insights
                </Typography>
                {pageData &&
                    <Fade in={Boolean(pageData)}>
                        <Box>
                            <Box
                                className='html-section'
                                sx={{
                                    maxWidth:'750px'
                                }}
                            >
                                {parse(pageData.data.data.attributes.intro)}
                            </Box>
                            <Box
                                sx={{
                                    height: '600px',
                                    mt: '4rem'
                                }}
                            >
                                <InsightPreview featured insight={pageData.data.data.attributes.featured_insight.data} />
                            </Box>
                            <InsightsList />
                        </Box>
                    </Fade>
                }
            </Box>
        </Container>
    );
}

export default Insights;