import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

export default function Cursor() {

    const [cursorX, setCursorX] = useState(0);
    const [cursorY, setCursorY] = useState(0);
    const [isClicking, setIsClicking] = useState(false);
    const [buttonHovered, setButtonHovered] = useState(false);

    const move = (e) => {
        const touchEvent = e.touches ? e.touches[0] : null;
        const x = !isMobile ? e.clientX : touchEvent?.clientX || 0;
        const y = !isMobile ? e.clientY : touchEvent?.clientY || 0;

        setCursorX(x);
        setCursorY(y);

        // Set the cursor border's position directly
        const cursorBorder = document.getElementById('cursor-border');
        if (cursorBorder) {
            cursorBorder.style.left = `${x}px`;
            cursorBorder.style.top = `${y}px`;
        }
    };

    const handleMouseOver = (event) => {
        //console.log('Mouse over event', event.target.classList);
        //check for a href or button
        if (event.target.classList && (event.target.classList.contains('hover') || event.target.tagName === 'A' || event.target.tagName === 'BUTTON')) {
            //console.log('hover');
            // Add your custom logic here
            setButtonHovered(true);
        } else {
            setButtonHovered(false);
        }
    };

    const handleMouseOut = () => {
        setButtonHovered(false);
    };

    // useEffect(() => {
    //     // Attach the event listener to the document
    //     document.addEventListener('mouseover', handleMouseOver);
    //     document.addEventListener('mouseleave', handleMouseOver);
    //     // Cleanup the event listener on component unmount
    //     return () => {
    //         document.removeEventListener('mouseover', handleMouseOver);
    //         document.removeEventListener('mouseleave', handleMouseOut);
    //     };
    //     // const links = document.querySelectorAll('a');
    //     // const hovers = document.querySelectorAll('.hover');

    //     // links.forEach(link => {
    //     //     console.log(link)
    //     //     link.addEventListener('mouseover', handleLinkHover);
    //     //     link.addEventListener('mouseout', handleLinkLeave);
    //     // });
    //     // hovers.forEach(hover => {
    //     //     hover.addEventListener('mouseover', handleLinkHover);
    //     //     hover.addEventListener('mouseout', handleLinkLeave);
    //     // });

    //     // return () => {
    //     //     links.forEach(link => {
    //     //         link.removeEventListener('mouseover', handleLinkHover);
    //     //         link.removeEventListener('mouseout', handleLinkLeave);
    //     //     });
    //     //     hovers.forEach(hover => {
    //     //         hover.removeEventListener('mouseover', handleLinkHover);
    //     //         hover.removeEventListener('mouseout', handleLinkLeave);
    //     //     });
    //     // };
    // }, []);


    useEffect(() => {
        if (!isMobile) {
            document.addEventListener('mousemove', move);
            document.addEventListener('touchmove', move);
        }

        return () => {
            document.removeEventListener('mousemove', move);
            document.removeEventListener('touchmove', move);
        };
    }, [isMobile]);

    return (
        <>
            {/* <Box
                id={'cursor'}
                sx={{
                    position: 'fixed',
                    backgroundColor: theme => buttonHovered ? theme.palette.yellow : '#fff',
                    height: '10px',
                    width: '10px',
                    borderRadius: '50%',
                    transform: 'translate(-50%, -50%)',
                    pointerEvents: 'none',
                    transition: 'background-color 0.2s ease',
                    zIndex: 9999,
                    left: `${cursorX}px`,
                    top: `${cursorY}px`
                }}

            /> */}
            <Box
                id={'cursor-border'}
                sx={{
                    position: 'fixed',
                    backgroundColor: theme => buttonHovered ? theme.palette.yellow : '#fff',
                    border: theme => `3px solid ${buttonHovered ? theme.palette.yellow : '#fff'}`,
                    height: buttonHovered ? "100px" : "50px",
                    width: buttonHovered ? "100px" : "50px",
                    borderRadius: '50%',
                    transform: 'translate(-50%, -50%)',
                    pointerEvents: 'none',
                    transition: 'all 0.1s ease-out',
                    zIndex: 1000,
                    opacity: buttonHovered ? 0.5 : 0.2
                }}
            />
            {/* <style>
                {`
                #cursor-border {
                    position: fixed;
                    width: ${buttonHovered ? "100px" : "50px"};
                    height: ${buttonHovered ? "100px" : "50px"};
                    background-color: transparent;
                    border: 3px solid ${buttonHovered ? 'red' : '#fff'};
                    border-radius: 50%;
                    transform: translate(-50%, -50%);
                    pointer-events: none;
                    transition: all 0.2s ease-out;
                    z-index: 9999;
                }
            `}
            </style>
            <div
                id="cursor"
                style={{ left: `${cursorX}px`, top: `${cursorY}px` }}
            ></div>
            <div id="cursor-border"></div> */}
        </>
    );
}
