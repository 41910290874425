import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { Parallax } from 'react-scroll-parallax';
import DMLink from '../layout/DMLink';
import { useVisible } from 'react-hooks-visible';

function HomeFamily({ data }) {

	const mobile=useMediaQuery(theme=>theme.breakpoints.down('md'));

	//Fade in animation
	const [ref, visible] = useVisible((vi) => vi > (mobile ? 0.1 : 0.5));
	const [display,setDisplay]=useState(false);
	useEffect(() => {
		if(visible && !display){
			setDisplay(true);
		}
	}, [visible]);

	return (
		<Box
			sx={{
				backgroundColor: theme=> theme.palette.yellow
			}}
		>
			<Container maxWidth='xl'
			         className="dm-section"
					 data-color='#01153C'>
				<Box
					sx={{
						p: mobile? '4rem 0' : '8rem 0',
						color: theme=> theme.palette.blue,
						backgroundColor: theme=> theme.palette.yellow
					}}
				>
					{data && 
						<Grid container spacing={2} direction={ mobile? 'column-reverse' : 'row' }>
							<Grid item xs={12} md={6}
								sx={{ pr: mobile? 0 : '3rem' }}
							>
								<Typography variant={mobile? 'h3' : 'h2'} component='h2'
									sx={{ 
										maxWidth:'600px', 
										fontWeight:'bold',
										//opacity: display? 1 : 0,
										transition: 'opacity 0.5s',
									}}
								>
									{data.familytitle}
								</Typography>
								<Typography variant='p' component='p' 
									sx={{ 
										m:'3rem 0', 
										width: mobile? 
										'100%' : '90%',
										opacity: display? 1 : 0,
										transition: 'opacity 0.5s',
										transitionDelay:'0.2s'
									}}
								>
									{data.familyparagraph}
								</Typography>
								<Box
									sx={{
										opacity: display? 1 : 0,
										transition: 'opacity 0.5s',
										transitionDelay:'0.4s'
									}}
								>
									<DMLink
										text='Become part of the family'
										link='/about'
										hoverColor='#fff'
									/>
								</Box>
								<Box
									ref={ref}
									sx={{
										mt:'4rem',
										display:'flex',
										opacity: display? 1 : 0,
										transition: 'opacity 0.5s',
										transitionDelay:'0.6s'
									}}
								>
									<a 
										href="https://ecologi.com/designmonkeymedialtd?r=644c2ae248beaa40346fbddc" 
										title="View our Ecologi profile" 
										aria-label="View our Ecologi profile" 
										target="_blank" 
										rel="noopener noreferrer" 
										style={{ width:'200px', display:'inline-block' }}
									>
										<img alt="We offset our carbon footprint via Ecologi" src="https://ecologi-assets.imgix.net/badges/climate-positive-workforce-black-landscape.png" style={{width:'200px'}}/>
									</a>
								</Box>
							</Grid>
							<Grid item xs={12} md={6}>
								<Box
									sx={{
										//position:'relative',
										display:'flex',
										alignItems:'center',
										width:'100%',
										height:'100%',
										m: mobile? '4rem 0' : 0,
									}}
								>
									<Parallax
										speed={5}
										style={{
											//filter:'drop-shadow(0 0 0.25rem #333333)',
											marginBottom:'3rem',
											zIndex:1
										}}
									>
										<img
											src={process.env.REACT_APP_BACKEND+data.familymedia.data[0].attributes.url}
											alt={data.familymedia.data[0].attributes.alternativeText}
											width='100%'
											style={{
												transform:'scale(1.1)',
											}}
										/>
									</Parallax>
									<Parallax
										speed={-5}
										style={{
											//filter:'drop-shadow(0 0 0.25rem #333333)',
											marginTop:'3rem',
										}}
									>
										<img
											src={process.env.REACT_APP_BACKEND+data.familymedia.data[1].attributes.url}
											alt={data.familymedia.data[1].attributes.alternativeText}
											width='100%'
											style={{
												transform:'scale(1.1)'
											}}
										/>
									</Parallax>
								</Box>
							</Grid>
						</Grid>
					}
				</Box>
			</Container>
		</Box>
	);
}

export default HomeFamily;