import { Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import parse from 'html-react-parser';
import { Parallax } from "react-scroll-parallax";

function SingleServiceContent({ service }) {

    const mobile = useMediaQuery(theme => theme.breakpoints.down('md'));

    return (
        <Box
            className="dm-section"
            data-color={'#01153C'}
            sx={{
                background:'#fff'
            }}
        >
            <Container maxWidth='xl'>
                <Box
                    sx={{
                        p: mobile ? '4rem 0' : '8rem 0',
                        color: theme => theme.palette.blue
                    }}
                >
                    {Object.values(service.content_blocks).map((block, i) => {
                        return (
                            <Grid container spacing={2} key={i}
                                sx={{
                                    mt: i === 0 ? 0 : '8rem',
                                    flexDirection: i % 2 === 0 ? 'row' : 'row-reverse'
                                }}
                            >
                                <Grid item xs={12} md={6}
                                    className='html-section'
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <Typography variant='h4' component='h4'
                                        sx={{
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        {block.block_title}
                                    </Typography>
                                    {parse(block.block_text)}
                                </Grid>
                                <Grid item xs={12} md={6}
                                    sx={{
                                        textAlign: i % 2 === 0 ? 'right' : 'left'
                                    }}
                                >
                                    <Parallax
                                        speed={ i%2===0? 5 : -5 }
                                    >
                                        <img
                                            src={process.env.REACT_APP_BACKEND + block.block_media.data.attributes.url}
                                            alt={block.block_media.data.attributes.alternativeText}
                                            width={mobile ? '100%' : '90%'}
                                        />
                                    </Parallax>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Box>
            </Container>
        </Box>
    )
}

export default SingleServiceContent;