import React from 'react';
import { Box } from '@mui/system';
import { Fade } from '@mui/material';

function Burger({ open, toggleOpen, color }) {

    return (
        <Fade in={Boolean(color)} timeout={1000}>
            <Box
                //className="hover"
                //id="burger"
                sx={{
                    height: '100%',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center'
                }}
                onClick={() => toggleOpen()}
            >
                <Box
                    sx={{
                        width: '32px',
                        mr:'10px'
                    }}
                >
                    <Box
                        className='burger-line'
                        sx={{
                            height: '5px',
                            width: '100%',
                            backgroundColor: color,
                            borderRadius: '2px',
                            m: '0px 10px 10px 0px',
                            transform: open ? 'rotate(-45deg)' : '0',
                            translate: open ? '0 7px' : '0',
                            transition: 'transform .2s, translate .2s'
                        }}
                    />
                    <Box
                        className='burger-line'
                        sx={{
                            display: open ? 'none' : 'block',
                            height: '5px',
                            width: '100%',
                            backgroundColor: color,
                            borderRadius: '2px',
                            m: '10px 0px 10px 10px',
                        }}
                    />
                    <Box
                        className='burger-line'
                        sx={{
                            height: '5px',
                            width: '100%',
                            backgroundColor: color,
                            borderRadius: '2px',
                            m: '10px 10px 0px 0px',
                            transform: open ? 'rotate(45deg)' : '0',
                            translate: open ? '0 -7px' : '0',
                            transition: 'transform .2s, translate .2s'
                        }}
                    />
                </Box>
            </Box>
        </Fade>
    );
}

export default Burger;