import { CardActionArea, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import parse from 'html-react-parser';

function ShowcasePreview({showcase}){

    const mobile=useMediaQuery(theme=>theme.breakpoints.down('md'));

    return(
        <CardActionArea
            component={RouterLink}
            to={'/showcases/'+showcase.attributes.slug}
            sx={{
                p: mobile? '1rem' : '2rem',
                '&:hover img':{
                    transform:'scale(1.1)'
                },
            }}
        >
            {showcase.attributes.images.data && 
                <Box
                    sx={{
                        overflow:'hidden',
                    }}
                >
                    <img
                        src={process.env.REACT_APP_BACKEND+showcase.attributes.images.data[0].attributes.url}
                        alt={showcase.attributes.images.data[0].attributes.alternativeText}
                        draggable={false}
                        style={{
                            width:'100%',
                            height: mobile? '25vh' : '50vh',
                            transition:'transform 0.5s',
                            objectFit:'cover',
                            objectPosition:'center'
                        }}
                    />
                </Box>
            }
            <Box
                sx={{
                    display:'flex',
                    flexWrap:'wrap',
                    mt:'1rem'
                }}
            >
                {Object.values(showcase.attributes.skills.data).map((skill, i)=>{
                    return(
                        <Typography
                            key={i}
                            sx={{
                                mr:'0.75rem',
                                color:theme=>theme.palette.yellow,
                                textTransform:'uppercase',
                                fontWeight:'bold'
                            }}
                        >
                            {skill.attributes.name}.
                        </Typography>
                    )
                })}
            </Box>
            <Typography variant='h4' component='h4'
                sx={{
                    mt:'1rem',
                    fontWeight:'bold',
                    color:theme=>theme.palette.blue
                }}
            >
                {showcase.attributes.title}
            </Typography>
            <Box
                className='html-section'
                sx={{
                    mt:'0.5rem'
                }}
            >
                {parse(showcase.attributes.summary)}
            </Box>
        </CardActionArea>
    );
}

export default ShowcasePreview;