import React, { useContext, useEffect } from 'react';
import axios from 'axios';
import AboutIntro from './AboutIntro';
import AboutEcologi from './AboutEcologi';
import AboutValues from './AboutValues';
import AboutTeamMembers from './AboutTeamMembers';
import { useQuery } from '@tanstack/react-query';
import { Box, Container, Skeleton } from '@mui/material';
import parse from "html-react-parser";
import { MetaContext } from "../context/MetaContext";

function About() {

	const meta=useContext(MetaContext);

	//Fetch homepage content
	const { data } = useQuery(({
		queryKey: ['about'],
		queryFn: async () => {
			const response = await axios.get(
				process.env.REACT_APP_BACKEND+'/api/about',
				{
					params:{
						'populate':'deep',
					}
				}
			);
			return response.data.data.attributes;
		}
	}));

	useEffect(()=>{
		//console.log(data)
		if(data){
			meta.setMeta(data.seo);
		}
	},[data]);

	//Set html background color
	useEffect(() => {
		document.body.style.backgroundColor = '#FFCD00'
	}, []);

	//Set header colour
	useEffect(()=>{
		window.dispatchEvent(new CustomEvent('headerColorChange',{detail:{color:'#01153C'}}));
	},[]);

	return(
		<>
			{data?
				<>
					<AboutIntro aboutData={data} />
					<AboutTeamMembers aboutData={data} />
					<Box
						sx={{
							p:'6rem 0',
							background:theme=>theme.palette.blue,
							color:'#fff',
						}}
					>
						<Container maxWidth='md'
							className='html-section'
						>
							{data?
								parse(data.reputation)
							:
								<Skeleton
									variant='rectangular'
									height={150}
								/>
							}
						</Container>
					</Box>
					<AboutEcologi aboutData={data} />
					<AboutValues aboutData={data} />
				</>
			:
				<Box
					data-color={'#01153C'}
					sx={{
						height:'100vh'
					}}
				>

				</Box>
			}
		</>
	);
}

export default About;