import { keyframes, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

const AnimatedText = ({
    text,
    sx,
    variant = 'p',
    component = 'p',
    delay = 0.05,
    opacity = 0.3,
    start = true,
    animation
}) => {

    const [splitText, setSplitText] = useState();
    useEffect(() => {
        if (text) {
            //Split by words first
            let words = text.split(' ');
            //then split by characters
            let characters = words.map(word => word.split(''));
            setSplitText(characters);
        }
    }, [start]);

    //default keyframe
    const fadeIn = keyframes`
        from { 	opacity: 0.3 }
        to { 	opacity: 1 }
    `;

    let count = 0

    return (

        <Typography
            variant={variant}
            component={component}
            sx={{
                mt: '2rem',
                '& span': {
                    display: 'inline-block',
                    opacity: opacity, // Start with hidden text
                    animation: `${animation || fadeIn} 0.5s forwards`,
                },
                ...sx,
            }}>
            {start && splitText && splitText.map((words, wordIndex) => {
                return (
                    <span
                        key={wordIndex}
                    >
                        {words.map((chars, index) => {
                            count = count + 1;
                            return (
                                <span
                                    style={{
                                        animationDelay: `${count * delay}s`
                                    }}
                                    key={index}>
                                    {chars}
                                </span>
                            )
                        })}
                        {wordIndex < splitText.length - 1 && <span>&nbsp;</span>}
                    </span>

                )
            })}
        </Typography>
    );
};

export default AnimatedText;
