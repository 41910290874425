import React from 'react';
import { Box, Typography, useMediaQuery } from "@mui/material";
import DMLink from "../layout/DMLink";

function AboutEcologi({ aboutData }) {

    const mobile = useMediaQuery(theme => theme.breakpoints.down('md'));

    return (
        <Box
            className="dm-section"
            data-color={'#FFF'}
            sx={{
                height: '100vh',
                position: 'relative',
                backgroundImage: 'url(' + process.env.REACT_APP_BACKEND + aboutData?.ecologipic.data.attributes.url + ')',
                backgroundSize: 'cover',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    height: '100%',
                    width: '100%',
                    backgroundColor: 'rgba(0,0,0,0.25)',
                }}
            >
                <Box
                    sx={{
                        height: '100%',
                        p: '1rem',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#fff',
                    }}
                >
                    <a 
                        href="https://ecologi.com/designmonkeymedialtd?r=644c2ae248beaa40346fbddc"
                        title="View our Ecologi profile" 
                        aria-label='View our Ecologi profile'
                        target="_blank" 
                        rel="noopener noreferrer" 
                        style={{ width: '200px', display: 'inline-block' }}
                    >
                        <img alt="We offset our carbon footprint via Ecologi" src="https://ecologi-assets.imgix.net/badges/climate-positive-workforce-black-landscape.png" style={{ width: '200px' }} />
                    </a>
                    <Typography variant={mobile? 'h4' : 'h3'} component='h3'
                        sx={{ maxWidth: '800px', textAlign: 'center', m: '2rem 0' }}
                    >
                        {aboutData?.ecologiparagraph}
                    </Typography>
                    <DMLink 
                        yellow 
                        text='See our impact' 
                        link='https://ecologi.com/designmonkeymedialtd?r=644c2ae248beaa40346fbddc'
                        target='_blank'
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default AboutEcologi;