import { Box, Container, Grid } from "@mui/material";
import AnimatedText from '../reusable/AnimatedText';
import parse from 'html-react-parser';

export default function SingleShowcaseContent({ content, underImages=false }){

    return(
        <Box
            className='dm-section'
            data-color={underImages? '#01153C' : '#fff'}
            sx={{
                pt:{
                    xs: underImages? 0 : '4rem',
                    md: underImages? 0 : '8rem'
                },
                pb:{
                    xs:'4rem',
                    md:'8rem'
                },
                background: theme=> underImages? '#fff' : theme.palette.yellow
            }}
        >
            <Container maxWidth='xl'>
                <Grid container spacing={4} direction={content.heading_left? 'row' : 'row-reverse'}>
                    <Grid item xs={12} md={5}>
                        <AnimatedText
                            text={content.heading}
                            variant='h3'
                            component='h1'
                            sx={{
                                color:theme=>theme.palette.blue
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={7}
                        className='html-section'
                        sx={{
                            '& p':{
                                color:theme=>theme.palette.blue
                            }
                        }}
                    >
                        {parse(content.content)}
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}