import { keyframes, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import AnimatedText from '../reusable/AnimatedText';

export default function HomeHeading({ text }){

    const [words, setWords]=useState([]);
    const [currentWord, setCurrentWord]=useState(0);
    const [start, setStart]=useState(false);

    useEffect(()=>{
        if(text){
            const theWords=text.split('\n');
            setWords(theWords);
            setStart(true);
            //Animation interval
            const interval=setInterval(()=>{
                setStart(false);
                setCurrentWord((prevCurrentWord) => {
                    if (prevCurrentWord === theWords.length - 1) {
                        setTimeout(()=>setStart(true), 10);
                        return 0;
                    } else {
                        setTimeout(()=>setStart(true), 10);
                        return prevCurrentWord + 1;
                    }
                });
            }, 4000);
            return()=>clearInterval(interval);
        }
    },[text]);

    if(words){
        return(
            <Typography
                variant='h1'
                component='h1'
                sx={{
                    color:'#fff',
                    textAlign:'center',
                    lineHeight:'0 !important',
                    m:'0 0.5rem'
                }}
            >
                <Typography
                    component='span'
                    sx={{
                        fontSize:'clamp(1.5rem, 5vw, 4rem)'
                    }}
                >
                    Together we are
                </Typography>
                <br/>
                <AnimatedText
                    variant='h1'
                    component='span'
                    delay={0.1}
                    start={start}
                    opacity={0}
                    animation={keyframes`
                        0% { 	opacity: 0 }
                        100% { 	opacity: 1 }
                    `}
                    text={words[currentWord]}
                    sx={{
                        fontSize:'clamp(3rem, 15vw, 10rem)'
                    }}
                />
            </Typography>
        );
    }
}