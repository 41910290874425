import { Container, Fade, Grid, Slide, Typography, useMediaQuery, useScrollTrigger } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Burger from './Burger';
import Logo from './Logo';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import DMLink from './DMLink';
import { Link as RouterLink } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';

//Hide header on scroll down
function HideOnScroll(props) {
	const { children, window } = props;
	const trigger = useScrollTrigger({
		target: window ? window() : undefined,
	});
	return (
		<Slide
			appear={false && props.open}
			direction="down"
			in={props.open ? true : !trigger}
			timeout={250}
		>
			{children}
		</Slide>
	);
}

function Header(props) {

	const mobile = useMediaQuery(theme => theme.breakpoints.down('md'));
	const [open, setOpen] = useState(false);
	const [menuZIndex, setMenuZIndex] = useState(-999);
	const [dataColour, setDataColour] = useState('#01153C');
	const [colour, setColour] = useState('#fff');
	const [mainMenu, setMainMenu] = useState();
	const [selectedSlug, setSelectedSlug] = useState();
	const [subMenu, setSubMenu] = useState();
	const [showSubMenu, setShowSubMenu] = useState(false);
	const queryClient = useQueryClient();

	//Set the header color when navigating or scrolling
	function setHeaderColor() {
		//get the header
		const header = document.getElementById('header');
		if (!header) return;
		const headerHeight = header.offsetHeight;
		const sections = document.querySelectorAll('.dm-section');
		if (!sections) return;
		//loop through the sections
		sections.forEach(section => {
			const sectionRect = section.getBoundingClientRect();
			const sectionTop = sectionRect.top;
			const sectionBottom = sectionRect.bottom;
			//set the color of the elements based on the section in view
			if (sectionTop <= headerHeight && sectionBottom > 0) { // If the section is in view
				// Get the color attribute of the section
				const color = section.getAttribute('data-color');
				//console.log(color)
				setDataColour(color)
				setColour(color)
			}
		});
	}

	//Listen for navigation events
	useEffect(() => {
		setHeaderColor();
		setSelectedSlug(window.location.pathname.split('/')[1]);
	}, [window.location.pathname]);

	//Listen for scroll events
	useEffect(() => {
		window.addEventListener('scroll', setHeaderColor);
		window.addEventListener('load', setHeaderColor);
		return () => {
			window.removeEventListener('scroll', setHeaderColor);
			window.removeEventListener('load', setHeaderColor);
		}
	}, []);

	//Listen for header colour events
	useEffect(()=>{
		function handleHeaderColorChange(event){
			setColour(event.detail.color);
			setDataColour(event.detail.color);
		}
		window.addEventListener('headerColorChange', handleHeaderColorChange);
		return()=>window.removeEventListener('headerColorChange', handleHeaderColorChange);
	},[]);

	//Toggle menu open / closed
	function toggleOpen() {
		if (open) {
			setOpen(false);
			setTimeout(() => {
				setMenuZIndex(-999);
			}, 200);
			setColour(dataColour);
			//add scroll to body
			document.body.style.overflow = 'auto';
		} else {
			//remove scroll from body
			document.body.style.overflow = 'hidden';
			setOpen(true);
			setMenuZIndex(999);
			setColour('#FFFFFF');
		}
	}

	//Fetch main menu
	const { data } = useQuery(({
		queryKey: ['main-menu'],
		queryFn: () => axios.get(`${process.env.REACT_APP_BACKEND}/api/main-menu?populate=deep`)
	}));

	useEffect(() => {
		if (data?.data?.data?.attributes?.blocks) {
			setMainMenu(data.data.data.attributes.blocks);
		}
	}, [data]);

	return (
		<>
			<HideOnScroll open={open} {...props}>
				<Box
					className='header'
					id='header'
					sx={{
						p: {
							xs: '1rem',
							md: '2rem'
						},
						zIndex: 1400,
						position: 'fixed',
						width: {
							xs: 'calc(100vw - 2rem)',
							md: 'calc(100vw - 4rem)'
						},
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between'
					}}
				>
					<Box
						className="hover"
					>
						<Logo
							color={colour}
							open={open}
							logoClicked={() => {
								if (open) {
									toggleOpen();
								}
							}}
						/>
					</Box>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center'
						}}
					>
						<Fade in={Boolean(colour)} timeout={1000} >
							<Typography variant='h3' component='h3'
								sx={{
									display: mobile ? 'none' : 'block',
									color: colour,
									mr: '4rem',
									textTransform: 'capitalize'
								}}
							>
								{window.location.pathname.split('/')[1]}
							</Typography>
						</Fade>
						<Burger
							open={open}
							toggleOpen={toggleOpen}
							color={colour}
						/>
					</Box>
				</Box>
			</HideOnScroll>
			<Box
				sx={{
					opacity: open ? '1' : '0',
					zIndex: menuZIndex,
					position: 'fixed',
					top: 0,
					width: '100vw',
					height: window.innerHeight,
					backgroundColor: theme => theme.palette.blue,
					transition: 'opacity .2s',
					display: 'flex',
					alignItems: 'center'
				}}
			>
				<Container maxWidth='xl'>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}
							sx={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
							}}
						>
							{mainMenu && Object.values(mainMenu).map((block, i) => {
								if (!['terms', 'privacy'].includes(block.slug)) {
									return (
										<Typography
											variant='h3'
											key={block.title}
											component={RouterLink}
											to={block.slug}
											onClick={toggleOpen}
											onMouseOver={() => {
												setSelectedSlug(block.slug);
												if (mainMenu[i].subitems.length) {
													setSubMenu(mainMenu[i].subitems);
													setShowSubMenu(true);
												}
												else {
													setShowSubMenu(false);
												}
												let pageSlug = block.slug.replace('/', '');
												//not sure why about is different
												if (block.slug !== 'about') {
													pageSlug = `${pageSlug}-page`;
												}
												//check if the page content is already in the cache
												if (queryClient.getQueryData(['page', pageSlug])) return;
												//preloading page content on hover
												queryClient.prefetchQuery({
													queryKey: ['page', pageSlug],//remove any slashes
													queryFn: () => axios.get(`${process.env.REACT_APP_BACKEND}/api/${pageSlug}?populate=deep`)
												});
											}}
											sx={{
												fontSize: mobile ? '3rem' : '4rem',
												color: theme => block.slug === selectedSlug ? theme.palette.yellow : 'white',
												'&:hover': { color: theme => theme.palette.yellow }
											}}
										>
											{block.title}
										</Typography>
									);
								}
							})}
						</Grid>
						<Grid item xs={12} md={6}
							sx={{
								display: mobile ? 'none' : 'flex',
								opacity: showSubMenu ? 1 : 0,
								transition: 'opacity 0.25s',
								flexDirection: 'column',
								justifyContent: 'center',
							}}
						>
							{!mobile && subMenu && Object.values(subMenu).map((block) => {
								return (
									<Typography variant='h3'
										key={block.title}
										component={showSubMenu ? RouterLink : 'span'}
										to={'/' + selectedSlug + '/' + block.slug}
										onClick={() => {
											if (showSubMenu) {
												toggleOpen();
											}
										}}
										sx={{
											color: theme => block.slug === selectedSlug ? theme.palette.yellow : 'white',
											'&:hover': { color: theme => theme.palette.yellow },
											cursor: showSubMenu ? 'pointer' : 'default'
										}}
									>
										{block.title}
									</Typography>
								);
							})}
						</Grid>
					</Grid>
					<Box
						sx={{
							position: 'absolute',
							width: 'calc(' + window.innerWidth + 'px - 4rem)',
							maxWidth: 'calc(1200px - 4rem)',
							bottom: mobile ? '2rem' : '3rem',
							color: 'white',
							display: 'flex',
							flexDirection: mobile ? 'column-reverse' : 'row',
						}}
					>
						<Box
							sx={{
								display: 'flex',
								'& a:hover': {
									color: theme => theme.palette.yellow
								}
							}}
						>
							<a 
								href='https://www.facebook.com/DesignMonkeyMediaLtd/'
								aria-label='View our Facebook profile'
								target='_blank' 
								style={{ marginRight: '1rem' }}
							>
								<FacebookIcon
									sx={{ fontSize: '2rem' }}
									className='magnetised'
								/>
							</a>
							<a 
								href='https://x.com/DesignMonkeyuk'
								aria-label='View our X profile'
								target='_blank' 
								style={{ marginRight: '1rem' }}
							>
								<XIcon
									sx={{ fontSize: '2rem' }}
									className='magnetised'
								/>
							</a>
							<a 
								href='https://www.linkedin.com/company/design-monkey-media-ltd/' 
								aria-label='View our LinkedIn profile'
								target='_blank' 
								style={{ marginRight: '1rem' }}
							>
								<LinkedInIcon
									sx={{ fontSize: '2rem' }}
									className='magnetised'
								/>
							</a>
							<a 
								href='https://www.instagram.com/designmonkeymedia/' 
								aria-label='View out Instagram profile'
								target='_blank' 
								style={{ marginRight: '3rem' }}
							>
								<InstagramIcon
									sx={{ fontSize: '2rem' }}
									className='magnetised'
								/>
							</a>
						</Box>
						<Box
							sx={{
								display: 'flex',
								mb: mobile ? '0.5rem' : 0,
							}}
						>
							{mainMenu && Object.values(mainMenu).map((block) => {
								if (['terms', 'privacy'].includes(block.slug)) {
									return (
										<Typography variant='h5'
											key={block.title}
											component={RouterLink}
											to={block.slug}
											onClick={toggleOpen}
											sx={{
												mr: '1rem',
												color: 'white',
												'&:hover': { color: theme => theme.palette.yellow }
											}}
										>
											{block.title}
										</Typography>
									)
								}
							})}
						</Box>
						<Box
							onClick={()=>setOpen(false)}
							sx={{
								display: mobile ? 'none' : 'block',
								ml: 'auto'
							}}
						>
							<DMLink 
								yellow 
								text='Start your project' 
								link='/contact'
							/>
						</Box>
					</Box>
				</Container>

			</Box>
		</>
	);
}

export default Header;