import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {

    // Multiple products (previously known as trackers)
    ReactGA.initialize([{
        //trackingId: "G-E8DGJK1BW2",
        //gaOptions: { ...}, // optional
        //gtagOptions: { ...}, // optional
      },
      {
        //trackingId: "your second GA measurement id",
      },
    ]);

    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
    //console.log('ga')

    // Send a custom event
    // ReactGA.event({
    //   category: "your category",
    //   action: "your action",
    //   label: "your label", // optional
    //   value: 99, // optional, must be a number
    //   nonInteraction: true, // optional, true/false
    //   transport: "xhr", // optional, beacon/xhr/image
    // });

  }, [location]);
};

export default usePageTracking;