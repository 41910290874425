import { Box, Container, Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import parse from 'html-react-parser';

function ShowcasesIntro({ pageData }) {

    const mobile = useMediaQuery(theme => theme.breakpoints.down('md'));

    return (
        <Box
            className="dm-section"
            data-color='#FFF'
            sx={{
                backgroundColor:theme => theme.palette.blue,
                color: 'white',
                pt:{
                    xs:'8rem',
                    md:'16rem'
                }
            }}
        >
            <Container maxWidth='xl'>
                <Box>
                    {pageData &&
                        <>
                            <Grid container spacing={2} alignItems='center'>
                                <Grid item xs={12} md={4}>
                                    <Typography variant={mobile ? 'h3' : 'h2'} component='h2'
                                        sx={{
                                            maxWidth:{
                                                xs:'none',
                                                md:'300px'
                                            },
                                            color:theme=>theme.palette.yellow
                                        }}
                                    >
                                        {pageData.header}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={8} 
                                    className='html-section'
                                    sx={{ 
                                        display: 'flex', 
                                        alignItems: 'center',
                                    }}
                                >
                                    {parse(pageData.intro)}
                                </Grid>
                            </Grid>
                        </>
                    }
                </Box>
            </Container>
        </Box>
    );
}

export default ShowcasesIntro;