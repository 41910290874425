import React from 'react';
import { Container, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import parse from "html-react-parser";

function HomeIntro({ data }) {

	const mobile = useMediaQuery(theme => theme.breakpoints.down('md'));

	return(
		<Container maxWidth='lg'>
			<Box
				className="dm-section html-section"
				data-color='#01153c'
				sx={{
					p: mobile ? '4rem 0' : '4rem 0 16rem 0',
					color:'white'
				}}
			>
				{parse(data.introduction)}
			</Box>
		</Container>
	);
}

export default HomeIntro;