import { Fade, Grid, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DMLink from '../layout/DMLink';
import { useVisible } from "react-hooks-visible";
import { Link as RouterLink } from "react-router-dom";
import getWindowWidth from "../functions/getWindowWidth";
import parse from 'html-react-parser';

function ServicesList({ servicesData }) {

    const mobile = useMediaQuery(theme => theme.breakpoints.down('md'));

    const [currentService, setCurrentService] = useState(0);

    //Block Animation
    const [display, setDisplay] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setDisplay(true);
        }, 200);
    }, []);

    //List Animation
    const [ref, visible] = useVisible((vi) => vi > 0.5);
    const [displayList, setDisplayList] = useState(false);
    useEffect(() => {
        if (visible && !displayList) {
            setDisplayList(true);
        }
    }, [visible]);

    return(
        <Box
            className="dm-section"
            data-color='#01153C'
            sx={{
                backgroundColor: theme => theme.palette.yellow,
                color:theme=>theme.palette.blue,
                mt: mobile? 0 : '-24rem',
                ml: mobile? 0 : `calc(calc(calc(${getWindowWidth()}px - 1200px)/2) - 3rem)`,
                pt: mobile ? '4rem' : '8rem',
                pb: mobile ? '4rem' : '8rem',
                pr: mobile? '1rem' : `calc(calc(${getWindowWidth()}px - 1200px)/2)`,
                pl: mobile? '1rem' : '3rem',
                translate: display ? 0 : '-8rem',
                opacity: display ? 1 : 0,
                transition: 'translate 0.5s, opacity 0.5s',
            }}
        >
            <Box
                sx={{
                    m: mobile ? '0 1rem' : '0 1rem',
                }}
            >
                {servicesData &&
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}
                            ref={ref}
                        >
                            <Typography variant='h3' component='h3'
                                sx={{
                                    mb: '4rem'
                                }}
                            >
                                Our Services
                            </Typography>
                            {servicesData.map((service, i) => {
                                return (
                                    <Box
                                        key={i}
                                        onMouseEnter={()=>setCurrentService(i)}
                                        sx={{
                                            m: '2rem 0',
                                            opacity: displayList ? 1 : 0,
                                            transition: 'opacity 0.25s',
                                            transitionDelay: `${i * 0.1}s`,
                                        }}
                                    >
                                        <Typography variant='h4'
                                            key={i}
                                            component={RouterLink}
                                            to={'/services/'+service.attributes.slug}
                                            sx={{
                                                display: 'block',
                                                fontSize: '2rem',
                                                fontWeight: 700,
                                                display: 'flex',
                                                alignItems: 'center',
                                                opacity: mobile? 1 : currentService===i? 1 : 0.5,
                                                transition:'opacity 0.25s',
                                                '&:hover .MuiSvgIcon-root': {
                                                    opacity: '1',
                                                    marginLeft: '1rem'
                                                },
                                            }}
                                        >
                                            {service.attributes.name}
                                            <ArrowForwardIcon fontSize='large'
                                                sx={{
                                                    opacity: '0',
                                                    transition: 'opacity 0.25s, margin-left 0.25s'
                                                }}
                                            />
                                        </Typography>
                                    </Box>
                                );
                            })}
                        </Grid>
                        <Grid item xs={12} md={6}
                            sx={{
                                display: mobile ? 'none' : 'block',
                            }}
                        >
                            {servicesData?.map((service, i)=>{
                                if(currentService===i){
                                    return(
                                        <Fade in={currentService===i} key={i}>
                                            <Box>
                                                <img
                                                    src={process.env.REACT_APP_BACKEND + service.attributes.thumbnail.data.attributes.url}
                                                    alt={service.attributes.thumbnail.data.attributes.alternativeText}
                                                    draggable={false}
                                                    style={{
                                                        width:'100%',
                                                        height:'400px',
                                                        objectFit:'cover',
                                                        objectPosition:'center'
                                                    }}
                                                />
                                                <Box
                                                    className='html-section'
                                                    sx={{
                                                        m:'2rem 0',
                                                        fontSize:'1.2rem'
                                                    }}
                                                >
                                                    {parse(servicesData[currentService].attributes.preview_summary)}
                                                </Box>
                                                <DMLink
                                                    text='Learn More'
                                                    link={'/services/'+servicesData[currentService].attributes.slug}
                                                    hoverColor='#fff'
                                                />
                                            </Box>
                                        </Fade>
                                    );
                                }
                            })}
                        </Grid>
                    </Grid>
                }
            </Box>
        </Box>
    );
}

export default ServicesList;