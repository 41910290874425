import { Box, Container, Fade, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import parse from 'html-react-parser';
import { MetaContext } from "../context/MetaContext";

export default function GenericPage({ }){

    const {'*':slug}=useParams();
    const navigate=useNavigate();
    const meta=useContext(MetaContext);

    const { data } = useQuery(({
		queryKey:['generic-page', slug],
		queryFn: async () => {
			const response = await axios.get(
                process.env.REACT_APP_BACKEND+'/api/pages',
                {
                    params:{
                        'filters[slug][$eq]':slug,
                        'populate':'deep'
                    }
                }
            );
			return response.data.data;
		}
	}));

    const [pageData, setPageData]=useState();

    useEffect(()=>{
        //console.log(data)
        if(data){
            if(data.length){
                setPageData(data[0].attributes);
                meta.setMeta(data[0].attributes.seo);
            }
            else{
                navigate('/404');
            }
        }
    },[data]);

    //console.log(pageData)

    return(
        <Box
            className='dm-section'
            data-color='#01153C'
            sx={{
                minHeight:'100vh',
                p:{
                    xs:'6rem 0',
                    md:'12rem 0'
                },
                background:'#fff',
                color:theme=>theme.palette.blue
            }}
        >
            <Container maxWidth='xl'>
                {pageData &&
                    <Fade in={Boolean(pageData)}>
                        <Box>
                            <Typography variant='h2' component='h1'>
                                {pageData.title}
                            </Typography>
                            <Box
                                className='html-section'
                                sx={{
                                    maxWidth:'700px',
                                    mb:'4rem'
                                }}
                            >
                                {parse(pageData.intro)}
                            </Box>
                            <Box
                                className='html-section'
                            >
                                {parse(pageData.content)}
                            </Box>
                        </Box>
                    </Fade>
                }
            </Container>
        </Box>
    );
}