import React, { useState } from 'react';
import { Button, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { Link as RouterLink } from 'react-router-dom';

function InsightPreview({ insight, featured = false }) {

	//console.log(insight);

	const mobile = useMediaQuery(theme => theme.breakpoints.down('md'));

	function formatDate(date) {
		const thedate = new Date(date);
		const m = thedate.toLocaleString('default', { month: 'short' });
		const y = thedate.getFullYear();
		return m + ' ' + y;
	}

	const [hovered, setHovered] = useState(false);

	return (
		<Button
			component={RouterLink}
			to={'/insights/' + insight?.attributes?.slug}
			onMouseEnter={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
			sx={{
				position: 'relative',
				width: '100%',
				height: '100%',
				minHeight: '400px',
				cursor: 'pointer',
				overflow: 'hidden',
				textTransform: 'none',
				borderRadius: 0
			}}
		>
			<Box
				sx={{
					position: 'absolute',
					width: '100%',
					height: '100%',
					backgroundImage: 'url(' + process.env.REACT_APP_BACKEND + insight?.attributes?.thumbnail?.data.attributes?.url + ')',
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					scale: hovered ? '1.1' : '1',
					transition: 'scale 0.5s',
				}}
			/>
			<Box
				sx={{
					position: 'absolute',
					width: '100%',
					height: '100%',
					backgroundColor: 'rgba(0,0,0,0.25)',
					display: 'flex',
					alignItems: 'flex-end',
				}}
			>
				<Box
					sx={{
						m: mobile ? '1rem' : '2rem',
						color: 'white',
					}}
				>
					<Typography variant='h4' component='h4'
						sx={{
							display: featured ? 'block' : 'none',
							color: theme => theme.palette.yellow,
							fontWeight: 'bold',
							mb: '1rem'
						}}
					>
						FEATURED
					</Typography>
					<Typography variant='h3' component='h3'
						sx={{ color: theme => theme.palette.yellow }}
					>
						{insight?.attributes?.title}
					</Typography>
					<Typography variant='h5' component='p'>
						by {insight?.attributes?.author?.data?.attributes.name}, {formatDate(insight?.attributes?.publishedAt)}
					</Typography>
					<Typography variant='h6' component='p'>
						{insight?.attributes?.readtime} read
					</Typography>
				</Box>
			</Box>
		</Button>
	);
}

export default InsightPreview;