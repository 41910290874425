import React, { useEffect, useState, useContext } from "react";
import {
	Typography,
	Box,
	useMediaQuery,
	Grid,
	Fade,
	Button,
	Container,
} from "@mui/material";
import { useVisible } from "react-hooks-visible";
import Not from "../img/not.png";
import { Link as RouterLink } from "react-router-dom";
import { MetaContext } from "../context/MetaContext";

export default function NotFound() {

    const meta=useContext(MetaContext);
	
    useEffect(()=>{
		//Set html background color
        document.body.style.backgroundColor='#fff'
		//Set meta info
		meta.setMeta({
			metaTitle:'404 Page not found - Design Monkey Media Ltd',
			metaDescription:'You have searched for a page that does not exist.'
		})
    },[]);

	const mobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

	//play animation when div is 50% visible
	const [container, container_visible] = useVisible((vi) => vi > 0.5);
	const mdDown = useMediaQuery((theme) => theme.breakpoints.down("md"));
	const [playAnimation, setPlayAnimation] = useState(mdDown ? true : false);

	//play once per page load
	useEffect(() => {
		if (container_visible && !playAnimation) {
			setPlayAnimation(true);
		}
	}, [container_visible]);

	return (
		<Box
			className='dm-section'
			data-color='#01153C'
			sx={{
				p:'4rem 0',
				background:'#fff'
			}}
		>
			<Container
				maxWidth="xl"
				ref={container}
			>
				<Grid
					container
					spacing={2}
					justifyContent="center"
					alignContent="center"
				>
					<Grid container spacing={2} textAlign="center">
						<Fade in={true}>
							<Grid
								item
								xs={12}
								sx={{
									marginTop: mdDown ? "2em" : 0,
									marginBottom: "2em",
									"& svg": {
										width: "120px",
										height: "120px",
									},
								}}
							></Grid>
						</Fade>
						<Grid item xs={12}>
							<Typography
								variant="h2"
								component="h2"
								sx={{
									transition:
										"opacity .45s ease-in-out, bottom .45s ease-in-out",
									position: "relative",
									bottom: playAnimation ? 0 : "-20px",
									opacity: playAnimation ? 1 : 0,
									fontWeight: "bold",
									color: (theme) => theme.palette.blue,
									fontSize: { xs: "2rem", md: "3.5rem" },
								}}
							>
								Sorry, Page Not Found.
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Box
								sx={{
									width: "100%",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									position: "relative",
								}}
							>
								<img
									src={Not}
									alt="404 Not Found"
									style={{
										width: "100%",
										height: "auto",
										maxWidth: "500px",
										display: "block",
										margin: "auto",
									}}
								/>
							</Box>
						</Grid>
						<Grid item xs={12} sx={{ marginBottom: "2em" }}>
							<Typography
								variant="body1"
								component="p"
								sx={{
									transition:
										"opacity .45s .15s ease-in-out, bottom .45s .15s ease-in-out",
									position: "relative",
									bottom: playAnimation ? 0 : "-50px",
									opacity: playAnimation ? 1 : 0,
									color: (theme) => theme.palette.blue,
									fontSize: "1.2rem",
								}}
							>
								Sorry about that, it looks like you have gone to a page that doesn't exist!
							</Typography>
						</Grid>
						<Grid
							container
							item
							spacing={2}
							justifyContent="center"
							justifyItems="center"
							sx={{ marginBottom: mdDown ? "2em" : 0 }}
						>
							<Grid item>
								<Button
									component={RouterLink}
									to='/'
									sx={{
										transition:
											"opacity .45s .5s ease-in-out, bottom .45s .5s ease-in-out",
										position: "relative",
										bottom: playAnimation ? 0 : "-50px",
										opacity: playAnimation ? 1 : 0,
										backgroundColor: (theme) => theme.palette.blue,
										color: (theme) => theme.palette.yellow,
										fontSize:'1.4rem',
										fontWeight: "bold",
										p: "0.5rem 3rem",
										ml: "auto",
										mb: "2rem",
										mt: mobile ? "1rem" : 0,
										width: mobile ? "calc(100% - 6rem)" : "auto",
										textAlign: "center",
										"&:hover": {
											backgroundColor: (theme) => theme.palette.blue,
											color: (theme) => theme.palette.yellow,
										},
									}}
								>
									Go Home
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
}
