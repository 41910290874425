import { Box, Container, Typography } from "@mui/material";

export default function Quote({ quote }){

    return(
        <Box
            className='dm-section'
            data-color='#fff'
            sx={{
                backgroundColor:theme=>theme.palette.blue,
                color:'#fff',
                p:{
                    xs:'4rem 0',
                    md:'8rem 0'
                }
            }}
        >
            <Container maxWidth='lg'>
                <Typography variant='p' component='p'
                    sx={{
                        fontSize:{
                            xs:'1.6rem',
                            md:'2rem'
                        },
                        fontWeight:'bold'
                    }}
                >
                    "{quote.quote}"
                </Typography>
                <Box
                    sx={{
                        mt:'2rem',
                        display:'flex',
                        flexDirection:{
                            xs:'column',
                            md:'row'
                        },
                        alignItems:'center'
                    }}
                >
                    <Box
                        sx={{
                            height:'5rem',
                            width:'5rem',
                            borderRadius:'5rem',
                            backgroundImage:'url('+process.env.REACT_APP_BACKEND+quote.person_image.data?.attributes?.url+')',
                            backgroundSize:'cover',
                            backgroundPosition:'center',
                            mr:'1.5rem',
                            mb:{
                                xs:'1rem',
                                md:0
                            }
                        }}
                    />
                    <Box
                        sx={{
                            textAlign:{
                                xs:'center',
                                md:'left'
                            }
                        }}
                    >
                        <Typography variant='h6' component='p'
                            sx={{
                                fontWeight:'bold'
                            }}
                        >
                            {quote.person_name},
                        </Typography>
                        <Typography variant='h6' component='p'
                            sx={{
                                color:theme=>theme.palette.yellow,
                                fontWeight:'bold'
                            }}
                        >
                            {quote.person_title}
                        </Typography>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
}