import { useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import getWindowWidth from "../functions/getWindowWidth";
import AnimatedText from "../reusable/AnimatedText";

function ServicesIntro({ pageData }) {

    const mobile = useMediaQuery(theme => theme.breakpoints.down('md'));

    //Animation
    const [display, setDisplay] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setDisplay(true);
        }, 200);
    }, []);

    return(
        <Box
            data-color='#FFCD00'
            className="dm-section"
            sx={{
                backgroundColor: theme => theme.palette.blue,
                color: '#fff',
                translate: display ? 0 : '-8rem',
                opacity: display ? 1 : 0,
                transition: 'translate 0.5s, opacity 0.5s',
                mr: mobile ? 0 : `calc(calc(calc(${getWindowWidth()}px - 1200px)/2) - 3rem)`,
                pt: mobile ? '4rem' : '8rem',
                pb: '4rem',
                pl: mobile ? '1rem' : `calc(calc(${getWindowWidth()}px - 1200px)/2)`,
                pr: mobile ? '1rem' : '3rem',
            }}
        >
            <Box
                sx={{
                    pb: mobile ? 0 : '30rem',
                    maxWidth: 'none',
                    m: '0 1rem',
                }}
            >
                {pageData &&
                    <>
                        <AnimatedText text={pageData.paragraph1} variant={mobile? 'h4' : 'h3'} component='h3' sx={{ mt: 0, fontWeight:'bold' }} />
                    </>
                }
            </Box>
        </Box>
    );
}

export default ServicesIntro;