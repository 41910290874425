import { Grid, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import InsightPreview from "./InsightPreview";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function InsightsList(){

    const mobile=useMediaQuery(theme=>theme.breakpoints.down('md'));
    
    const [page,setPage]=useState(1);
    const perPage=6;
    const [pageCount,setPageCount]=useState(0);

    const [categories,setCategories]=useState([{attributes:{name:'All'},id:0}]);
    const [selectedCategory,setSelectedCategory]=useState(0);
    const [categoriesExpanded,setCategoriesExpanded]=useState(false);

    const selectRef=useRef(null);
    const [selectHeight,setSelectHeight]=useState();
    
    const [insights,setInsights]=useState();

	useEffect(() => {
        //Fetch insight categories
        axios.get(
            process.env.REACT_APP_BACKEND+'/api/insight-categories',
            {
                params:{
                    'populate':'insights'
                }
            }
        ).then((response) => {
            //console.log(response)
            const hasInsights=[];
            response.data.data.forEach((cat)=>{
                //console.log(cat)
                if(cat.attributes.insights.data.length){
                    hasInsights.push(cat);
                }
            });
            //console.log(hasInsights)
			setCategories(categories.concat(hasInsights));
		});
	},[]);

    //Refetch insights
    useEffect(() => {
        //All categories
        if(selectedCategory===0){
            axios.get(
                process.env.REACT_APP_BACKEND+'/api/insights',
                {
                    params:{
                        'sort':'publishedAt:desc',
                        'pagination[page]':page,
                        'pagination[pageSize]':perPage,
                        'populate':'deep'
                    }
                }
            ).then((response) => {
                setInsights(response.data.data);
                setPageCount(response.data.meta.pagination.pageCount);
            });
        }
        //Specific category
        else{
            axios.get(
                process.env.REACT_APP_BACKEND+'/api/insights',
                {
                    params:{
                        'sort':'publishedAt:desc',
                        'pagination[page]':page,
                        'pagination[pageSize]':perPage,
                        'filters[insight_category][id][$eq]':selectedCategory,
                        'populate':'deep'
                    }
                }
            ).then((response) => {
                setInsights(response.data.data);
                setPageCount(response.data.meta.pagination.pageCount);
            }).catch((error)=>{
                console.error(error);
            });
        }
	}, [selectedCategory, page]);

    //When the category select is toggled
    useEffect(()=>{
        setSelectHeight(selectRef?.current?.scrollHeight);
    },[categoriesExpanded])

    //console.log(categories);
    //console.log(insights);

    return(
        <>
            <Box
                sx={{
                    display:'flex',
                    justifyContent: mobile? 'space-between' : 'center',
                    m: mobile? '3rem 1rem 3rem 0' : '8rem 0'
                }}
            >
                <Box
                    sx={{
                        height:selectHeight,
                        transition:'height 0.25s'
                    }}
                >
                    <Box
                        ref={selectRef}
                        sx={{
                            display:'flex',
                            flexDirection: mobile? 'column' : 'row',
                        }}
                    >
                        {categories?.map((category, i)=>{
                            return(
                                <Typography variant={mobile? 'h4' : 'h3'} component='h3'
                                    key={category.id}
                                    onClick={e=>{
                                        setSelectedCategory(category.id);
                                        setCategoriesExpanded(false);
                                    }}
                                    sx={{
                                        display: mobile? categoriesExpanded || category.id===selectedCategory? 'block' : 'none' : 'block',
                                        m:'0 1.5rem 1rem 1.5rem',
                                        cursor:'pointer',
                                        textDecoration: selectedCategory===category.id? 'underline' : 'none',
                                        textDecorationColor: theme=> selectedCategory===category.id? theme.palette.yellow : theme.palette.blue,
                                        textUnderlineOffset:'1rem',
                                        '&:hover':{
                                            textDecoration:'underline',
                                            textDecorationColor: theme=> selectedCategory===category.id? theme.palette.yellow : theme.palette.blue,
                                        }
                                    }}
                                >
                                    {category.attributes.name}
                                </Typography>
                            );
                        })}
                    </Box>
                </Box>
                <ExpandMoreIcon
                    onClick={()=>setCategoriesExpanded(!categoriesExpanded)}
                    sx={{
                        display: mobile? 'block' : 'none',
                        fontSize:'3rem',
                        cursor:'pointer',
                        rotate: categoriesExpanded? '180deg' : '0',
                        transition: 'rotate 0.25s'
                    }}
                />
            </Box>
            <Grid container spacing={6}>
                {insights && Object.values(insights).map((insight, i)=>{
                    return(
                        <Grid item xs={12} md={6} key={i}
                            sx={{
                                height:'600px'
                            }}
                        >
                            <InsightPreview insight={insight}/>
                        </Grid>
                    );
                })}
            </Grid>
            {pageCount>1 &&
                <Box
                    sx={{
                        mt:'8rem',
                        display:'flex',
                        justifyContent:'center'
                    }}
                >
                    {Array(pageCount).fill(0).map((count, i)=>{
                        return(
                            <Typography variant='h3' component='h3'
                                key={i}
                                onClick={e=>setPage(i+1)}
                                sx={{
                                    cursor:'pointer',
                                    mr:'1.5rem',
                                    textDecoration: i+1===page? 'underline' : 'none',
                                    textDecorationColor: theme=> i+1===page? theme.palette.yellow : theme.palette.blue,
                                    textUnderlineOffset:'1rem',
                                    '&:hover':{
                                        textDecoration:'underline',
                                        textDecorationColor: theme=> i+1===page? theme.palette.yellow : theme.palette.blue,
                                    }
                                }}
                            >
                                {i+1}
                            </Typography>
                        );
                    })}
                </Box>
            }
        </>
    );
}

export default InsightsList;