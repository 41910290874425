import { Container, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import DMLink from '../layout/DMLink';
import ShowcasePreview from "./ShowcasePreview";

function MoreShowcases({showcase}){

    const mobile=useMediaQuery(theme=>theme.breakpoints.down('md'));

    const [moreShowcases,setMoreShowcases]=useState();

    useEffect(() => {
	    //Fetch showcases
		axios.get(process.env.REACT_APP_BACKEND+'/api/showcases?filters[slug][$ne]='+showcase.slug+'&pagination[limit]=2&populate=deep').then((response) => {
			setMoreShowcases(response.data.data);
		});
	}, [showcase]);

    //console.log(moreShowcases);

    return(
        <Box
            className='dm-section'
            data-color='#01153C'
        >
            <Container maxWidth='xl'>
                <Box
                    sx={{
                        p: mobile? '4rem 0' : '8rem 0',
                        color:theme=>theme.palette.blue
                    }}
                >
                    <Typography
                        sx={{
                            fontSize:{
                                xs:'3rem',
                                md:'5rem'
                            },
                            lineHeight:{
                                xs:'3rem',
                                md:'5rem'
                            },
                            fontWeight:'bold',
                        }}
                    >
                        More<br/>Projects 
                    </Typography>
                    <ResponsiveMasonry
                        columnsCountBreakPoints={{0: 1, 600: 2}}
                        style={{
                            marginTop:'4rem'
                        }}
                    >
                        <Masonry>
                            {moreShowcases && Object.values(moreShowcases).map((showcase, i)=>{
                                return(
                                    <ShowcasePreview showcase={showcase} key={i}/>
                                );
                            })}
                        </Masonry>
                    </ResponsiveMasonry>
                </Box>
            </Container>
        </Box>
    );
}

export default MoreShowcases;