import { Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import parse from 'html-react-parser';

function SingleServiceIntro({ service }) {

    const mobile = useMediaQuery(theme => theme.breakpoints.down('md'));

    return (
        <Box
            className="dm-section"
            data-color={'#01153C'}
            sx={{
                background:'#fff'
            }}
        >
            <Container maxWidth='xl'>
                <Box
                    sx={{
                        p: mobile ? '4rem 0' : '8rem 0',
                        color: theme => theme.palette.blue
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={5}
                            sx={{
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <Typography variant={mobile ? 'h4' : 'h3'} component='h2'
                                sx={{
                                    mr:{
                                        xs:0,
                                        md:'3rem'
                                    },
                                }}
                            >
                                {service.intro_summary}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={7}
                            className='html-section'
                        >
                            {parse(service.intro_text)}
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
}

export default SingleServiceIntro;