import { Box, Container } from "@mui/material";
import parse from 'html-react-parser';
import DMLink from "../layout/DMLink";

export default function CTA({ cta, whiteBG=false }){

    return(
        <Box
            className='dm-section'
            data-color={whiteBG? '#01153C' : '#fff'}
            sx={{
                mt: whiteBG? 0 : '-4rem',
                position:'relative',
                background:'#fff'
            }}
        >
            <Box
                sx={{
                    position:'absolute',
                    height:'100%',
                    width:'50%',
                    background: theme=> whiteBG? '#fff' : theme.palette.blue,
                    translate:'0 4rem'
                }}
            />
            <Container maxWidth='xl'
                sx={{
                    background: theme=> whiteBG? '#fff' : theme.palette.blue,
                    transform:'translateZ(1px)',
                    translate:'0 4rem'
                }}
            >
                <Box
                    className='html-section'
                    sx={{
                        p:'4rem 0',
                        color: theme=> whiteBG? theme.palette.blue : '#fff'
                    }}
                >
                    {parse(cta)}
                    <DMLink
                        text='Get in touch'
                        link='/contact'
                        white={!whiteBG}
                        small
                    />
                </Box>
            </Container>
        </Box>
    );
}