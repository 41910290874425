import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import TeamMember from "./TeamMember";
import JoinTeam from "./JoinTeam";

function AboutTeamMembers({ aboutData }) {

	const mobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

	// Number of columns for the first row and subsequent rows
	const firstRowColumns = mobile? 2 : 2;
	const otherRowsColumns = mobile? 2 : 3;

	return(
		<Box
			sx={{
				backgroundColor: "#FFF",
			}}
		>
			<Container maxWidth="xl" className="dm-section" data-color={"#01153c"}>
				<Box
					sx={{
						p: mobile ? "8rem 0" : "16rem 0",
						color: (theme) => theme.palette.blue,
					}}
				>
					<Grid container spacing={2}>
						<Grid
							item
							xs={12}
							md={4}
							sx={{
								pr: mobile ? 0 : "2rem",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
							}}
						>
							<Typography variant="h2" component="h2">
								Family focused
							</Typography>
							<Typography variant="p" component="p" sx={{ mt: "1rem" }}>
								{aboutData?.familyparagraph}
							</Typography>
						</Grid>
						{aboutData?.team.map((teamMember, i) => {
							let columnIndex;
							// For the first row (both mobile and desktop)
							if (i < firstRowColumns) {
								columnIndex = i; // The column index is the same as the index for the first row
							} 
							// For the subsequent rows
							else{
								const offsetIndex = i - firstRowColumns; // Adjust for the first row images
								columnIndex = offsetIndex % otherRowsColumns; // Calculate column index
							}
							return (
								<Grid item xs={6} md={4} key={i}
									sx={{
										mt: mobile ? "2rem" : 0,
										transform: mobile ? "scale(1)" : "scale(0.8)",
										translate: mobile? 'none' : `${teamMember.offsetx}px ${teamMember.offsety}px`,
									}}
								>
									<TeamMember
										member={teamMember.member.data.attributes}
										i={i}
										delay={columnIndex}
									/>
								</Grid>
							);
						})}
						<Grid item xs={6} md={4}>
							{(() => {
								const i = aboutData?.team.length; // Get the index of <JoinTeam> as it comes after the team members
								const offsetIndex = i - firstRowColumns; // Adjust for the first row images
								const columnIndex = offsetIndex % otherRowsColumns; // Always calculate as if it's in the subsequent rows
								return (
									<JoinTeam
										i={i}
										delay={columnIndex} // Apply the delay based on the calculated columnIndex
									/>
								);
							})()}
						</Grid>
					</Grid>
				</Box>
			</Container>
		</Box>
	);
}

export default AboutTeamMembers;
