import { Button, Checkbox, FormControlLabel, Grid, TextField, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTheme } from "@emotion/react";
import axios from "axios";

export default function JoinTeamForm({ setSent }) {

	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down("md"));

	const formik = useFormik({
		initialValues: {
			fullName: "",
			email: "",
			phone: "",
			message: "",
			agree: false,
			file: null,
		},
		validationSchema: Yup.object({
			fullName: Yup.string().required("Please enter your full name"),
			email: Yup.string().email("Invalid email address").required("Please enter your email"),
			phone: Yup.number().typeError("Please enter a valid phone number").required("Please enter your phone number"),
			message: Yup.string().required("Please enter your message"),
			agree: Yup.boolean().required("Required").oneOf([true], "You must agree with Design Monkey's privacy policy."),
		}),
		onSubmit: (values) => {
			// Create FormData instance
			const formData = new FormData();
			// Append form fields
			formData.append('fullName', values.fullName);
			formData.append('email', values.email);
			formData.append('phone', values.phone);
			formData.append('message', values.message);
			formData.append('agree', values.agree);
			// Append file if present
			if (values.file) {
				//console.log(values.file)
				formData.append('file', values.file);
			}
			//Send to backend
			axios.post(
				process.env.REACT_APP_BACKEND + '/api/contact/join',
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}
			).then((response) => {
				//console.log(response);
				if(response.data==="success"){
					setSent(true);
				}
			}).catch((error) => {
				console.error(error);
			});
		},
	});

	// File input handler
	const handleFileChange = (event) => {
		formik.setFieldValue("file", event.currentTarget.files[0]);
	};

	const textFieldStyle = {
		color: "#fff !important",
		backgroundColor: "transparent !important",
		width: "100%",
		"& div": {
			color: "#fff",
			fontSize: "1.25rem",
			p: "0.5rem 0rem",
			border: "none",
		},
		"& div:after": {
			border: "none",
		},
		"& .MuiInputBase-root": {
			// borderBottom: '1px solid #fff',
			// transition: '0.35s ease all',
			"&:before": {
				borderBottom: "none !important",
				transition: "0.35s ease all",
				content: '""',
				position: "absolute",
				bottom: 0,
				left: 0,
				right: 0,
				height: "2px",
				background: "#fff",
				width: "100%",
				transform: "scale(1)",
			},
			"&:hover:before": {
				borderBottom: "none !important",
			},
			"&:after": {
				content: '""',
				position: "absolute",
				bottom: 0,
				left: 0,
				right: 0,
				height: "2px",
				background: theme.palette.yellow,
				width: "100%",
				transform: "scale(0)",
				transition: "0.35s ease all",
			},
			"&:hover:after": {
				transform: "scale(1.0)",
				transition: "0.35s ease all",
			},
			"&:focus:after": {
				transform: "scale(1.0)",
				transition: "0.35s ease all",
			},
			"&:focus-visible:after": {
				transform: "scale(1.0)",
				transition: "0.35s ease all",
			},
			"&:focus-visible": {
				outline: "none",
				border: 0,
				transition: "0.35s ease all",
				"&:after": {
					transform: "scale(1.0)",
					transition: "0.35s ease all",
				},
			},
		},
		"& .Mui-focused": {
			// borderBottom: '1px solid ' + theme.palette.yellow,
			border: 0,
			transition: "0.35s ease all",
			"&:after": {
				transform: "scale(1.0)",
				transition: "0.35s ease all",
			},
		},
		"&::placeholder": {
			color: "#fff",
		},
	};
	const textAreaStyle = {
		color: "#fff",
		width: "100%",
		mt: "2rem",
		"& div": {
			color: "#fff",
			fontSize: "1.25rem",
			p: "0.5rem 1rem",
		},
		"& div:after": {
			border: "none",
		},
		"& .MuiInputBase-root": {
			border: "2px solid #fff",
			"&:hover": {
				border: "2px solid " + theme.palette.yellow,
				transition: "0.35s ease all",
			},
		},
		"& .Mui-focused": {
			border: "2px solid " + theme.palette.yellow,
			transition: "0.35s ease all",
		},
	};

	return (
		<form
			onSubmit={formik.handleSubmit}
			style={{
				width: "100%",
				display: "flex",
				flexWrap: "wrap",
			}}
		>
			<Grid
				container
				spacing={2}
				sx={{
					maxWidth: "100%",
					width: "100%",
					ml: 0,
				}}
			>
				{/* Full name */}
				<Grid item xs={12}>
					<TextField
						variant="standard"
						placeholder="Full Name"
						name="fullName"
						sx={[
							textFieldStyle,
							{
								color: "#fff !important",
								backgroundColor: "transparent !important",
							},
						]}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.fullName}
						inputProps={{
							sx: {
								"&::placeholder": {
									color: "#fff",
									opacity: 1, // otherwise firefox shows a lighter color
								},
							},
						}}
					/>
					{formik.touched.fullName && formik.errors.fullName ? (
						<Typography
							sx={{
								color: theme.palette.yellow,
								fontSize: "0.95rem",
							}}
						>
							{formik.errors.fullName}
						</Typography>
					) : null}
				</Grid>
				{/* Email address */}
				<Grid item xs={12} md={6}>
					<TextField
						variant="standard"
						placeholder="Email"
						name="email"
						sx={[
							textFieldStyle,
							{
								color: "#fff !important",
								backgroundColor: "transparent !important",
							},
						]}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.email}
						inputProps={{
							sx: {
								"&::placeholder": {
									color: "#fff",
									opacity: 1, // otherwise firefox shows a lighter color
								},
							},
						}}
					/>
					{formik.touched.email && formik.errors.email ? (
						<Typography
							sx={{
								color: theme.palette.yellow,
								fontSize: "0.95rem",
							}}
						>
							{formik.errors.email}
						</Typography>
					) : null}
				</Grid>
				{/* Phone number */}
				<Grid item xs={12} md={6}>
					<TextField
						variant="standard"
						placeholder="Phone"
						name="phone"
						sx={[
							textFieldStyle,
							{
								color: "#fff !important",
								backgroundColor: "transparent !important",
							},
						]}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.phone}
						inputProps={{
							sx: {
								"&::placeholder": {
									color: "#fff",
									opacity: 1, // otherwise firefox shows a lighter color
								},
							},
						}}
					/>
					{formik.touched.phone && formik.errors.phone ? (
						<Typography
							sx={{
								color: theme.palette.yellow,
								fontSize: "0.95rem",
							}}
						>
							{formik.errors.phone}
						</Typography>
					) : null}
				</Grid>
				{/* Message */}
				<Grid item xs={12}>
					<TextField
						variant="standard"
						placeholder="Message"
						name="message"
						multiline
						minRows={6}
						sx={[
							textAreaStyle,
							{
								color: "#fff !important",
								backgroundColor: "transparent !important",
							},
						]}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.message}
						inputProps={{
							sx: {
								"&::placeholder": {
									color: "#fff",
									opacity: 1, // otherwise firefox shows a lighter color
								},
							},
						}}
					/>
					{formik.touched.message && formik.errors.message ? (
						<Typography
							sx={{
								color: theme.palette.yellow,
								fontSize: "0.95rem",
							}}
						>
							{formik.errors.message}
						</Typography>
					) : null}
				</Grid>
				{/* Agree checkbox */}
				<Grid item xs={12}>
					<FormControlLabel
						value={formik.values.agree}
						checked={formik.values.agree}
						onChange={formik.handleChange}
						name="agree"
						control={
							<Checkbox
								size="large"
								sx={{
									color: "#fff",
									borderRadius: "0",
									"&.Mui-checked": {
										color: theme.palette.yellow,
									},
								}}
							/>
						}
						label={
							<Typography>
								I agree with Design Monkey’s{" "}
								<a
									href="/privacy"
									aria-label="View our privacy policy"
									style={{ color: theme.palette.yellow }}
								>
									privacy policy
								</a>
							</Typography>
						}
						sx={{
							color: "#fff",
							"& .MuiCheckbox-root": {
								padding: theme.spacing(0.5),
							},
						}}
					/>
				</Grid>
				{/* CV Upload */}
				<Grid item xs={12}>
					<Box sx={{ display: "flex", flexDirection: mobile ? "column" : "row", alignItems: "center" }}>
						<Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
							<label htmlFor="file">
								<Typography sx={{ color: theme.palette.yellow, fontSize: "0.95rem", display: "block" }}>
									Attach your CV
								</Typography>
							</label>
							<input
								id="file"
								name="file"
								type="file"
								onChange={handleFileChange}
								style={{ color: "#fff", marginTop: theme.spacing(1) }}
							/>
							{formik.touched.file && formik.errors.file ? (
								<Typography sx={{ color: theme.palette.yellow, fontSize: "0.95rem" }}>
									{formik.errors.file}
								</Typography>
							) : null}
						</Box>
					</Box>
				</Grid>
				{/* Submit button */}
				<Grid item xs={12}>
					<Button 
						onClick={formik.handleSubmit}
						sx={{
							fontSize: "1.25rem",
							fontWeight: "bold",
							p: "0.35rem 3rem",
							mt: theme.spacing(1),
							width: mobile ? "calc(100% - 6rem)" : "auto",
							backgroundColor: theme.palette.yellow,
							color: theme.palette.blue,
							border: "1px solid " + theme.palette.yellow,
							borderRadius: "0",
							textAlign: "center",
							cursor: "pointer",
							transition: "0.35s ease all",

							"&:hover": {
								backgroundColor: theme.palette.blue,
								color: theme.palette.yellow,
								border: "1px solid " + theme.palette.yellow,
								transition: "0.2s ease all",
							},
						}}
					>
						Submit
					</Button>
				</Grid>
			</Grid>
		</form>
	);
}
