import React, { useEffect, useState, useCallback } from 'react';
import { Box } from '@mui/system';
import { Fade, useMediaQuery } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import HomeHeading from './HomeHeading';

function HomeVideo({ data }) {

	const mobile = useMediaQuery(theme => theme.breakpoints.down('md'));
	const [pageHeight, setPageHeight] = useState(window.innerHeight);
	const [videoSnap, setVideoSnap] = useState(false);
	const [scale, setScale] = useState(1);
	const [opacity, setOpacity] = useState(0);

	//Responsive sizing
	useEffect(() => {
		function handleResize() {
			setPageHeight(window.innerHeight);
		}
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	//Handle scroll
	const handleScroll = useCallback(() => {
		const scrollY = window.scrollY;
		if (scrollY > pageHeight) {
			setVideoSnap(true);
		} else {
			setVideoSnap(false);
		}
		//set a minimum scale of 0.6
		const minScale = 0.6;
		let newScale = 1 - (scrollY / (pageHeight * 2 - 400));
		if (newScale < minScale) {
			newScale = minScale;
		}
		//Fade opacity
		let newOpacity;
		if(mobile){
			newOpacity = (scrollY - pageHeight*2.4) / pageHeight;
		}
		else{
			newOpacity = (scrollY - pageHeight*1.8) / pageHeight;
		}
		if (newOpacity > 1) {
			newOpacity = 1;
		}
		if (newOpacity < 0) {
			newOpacity = 0;
		}
		setOpacity(newOpacity);
		setScale(newScale);
	}, [pageHeight]);

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [handleScroll]);

	return (
		<>
			<Box
				sx={{
					height: '100%',
					width: '100%',
					backgroundColor: 'white',
					opacity: opacity,
					position: 'absolute',
					top: 0,
					left: 0,
					zIndex: 0
				}}
			/>
			<Box
				id="spacer"
				sx={{
					height: '200vh',
					width: '100vw',
				}}
			>
				<Fade
					in={Boolean(data?.herovideo)}
					timeout={1000}
				>
					<Box
						id="pin"
						sx={{
							height: `${pageHeight * 2}px`,
							padding: 0,
							paddingBottom: `${pageHeight}px`,
							width: '100vw',
							position: 'relative',
							boxSizing: 'border-box',
						}}
					>
						<Box
							id="header-video-wrapper"
							sx={{
								position: videoSnap ? 'absolute' : 'fixed',
								width: '100%',
								height: '100vh',
								left: 0,
								top: videoSnap ? 'auto' : 0,
								bottom: videoSnap ? 0 : 'auto',
								margin: 0,
								padding: 0,
								boxSizing: 'border-box',
								transform: 'translate3d(0, 0, 0)',
							}}
						>
							<Box
								id="header-video"
								sx={{
									width: '100vw',
									height: '100vh',
									position: 'relative',
								}}
							>
								<Box
									id="header-video-inner"
									sx={{
										position: 'relative',
										zIndex: 0,
										height: '100%',
										overflow: 'hidden',
										transformOrigin: '50% 50%',
										transform: `scale(${scale})`,
										top: 0,
										left: 0,
										zIndex: 0,
									}}
								>
									<Box
										sx={{
											background: 'rgba(1, 21, 60, 0.5)',
											position: 'absolute',
											width: '100%',
											height: '100%',
											top: 0,
											left: 0,
											zIndex: 1,
										}}
									/>
									{data &&
										<video
											id='video'
											autoPlay
											loop
											muted
											playsInline
											style={{
												width: '100%',
												height: '100%',
												objectFit: 'cover',
											}}
										>
											<source src={
												mobile ?
													process.env.REACT_APP_BACKEND + data.herovideo_alt.data.attributes.url
													:
													process.env.REACT_APP_BACKEND + data.herovideo.data.attributes.url
											} />
										</video>
									}
									<Box
										sx={{
											height: '100%',
											width: '100%',
											top: 0,
											position: 'absolute',
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'center',
											alignItems: 'center',
											zIndex: 2,
											opacity: 1 - (window.scrollY / 500),
										}}
									>
										<Fade in={Boolean(data)} timeout={2000}>
											<Box
												sx={{
													height:'300px',
													opacity: 1 - (window.scrollY / 500),
												}}
											>
												<HomeHeading
													text={data?.herotext}
												/>
											</Box>
										</Fade>
									</Box>
								</Box>
							</Box>
							<Box
								sx={{
									position: 'absolute',
									bottom: mobile ? '2rem' : '3rem',
									color: 'white',
									textAlign: 'center',
									cursor: 'pointer',
									width: '100%',
									zIndex: 4,
								}}
							>
								<ArrowDownwardIcon
									className="hover"
									onClick={() => window.scrollTo({ top: (pageHeight * 2) - 120, behavior: 'smooth' })}
									sx={{
										fontSize: 50,
										opacity: 1 - (window.scrollY / 500),
									}}
								/>
							</Box>
						</Box>
					</Box>
				</Fade>
			</Box>
		</>
	);
}

export default HomeVideo;
