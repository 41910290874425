import { Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import InsightPreview from "./InsightPreview";
import DMLink from "../layout/DMLink";

function MoreInsights({insight}){

    const mobile=useMediaQuery(theme=>theme.breakpoints.down('md'));

    const [moreInsights,setMoreInsights]=useState();

    useEffect(() => {
	    //Fetch insights
        axios.get(
            process.env.REACT_APP_BACKEND+'/api/insights',
            {
                params:{
                    'filters[slug][$ne]':insight.slug,
                    'sort':'publishedAt:desc',
                    'pagination[limit]':2,
                    'populate':'deep'
                }
            }
        ).then((response) => {
			setMoreInsights(response.data.data);
		});
	}, [insight]);

    //console.log(moreInsights);

    return(
        <Box
            sx={{
                backgroundColor:theme=>theme.palette.yellow,
            }}
        >
            <Container maxWidth='xl'>
                <Box
                    sx={{
                        p: mobile? '4rem 1rem' : '8rem 0',
                        color:theme=>theme.palette.blue
                    }}
                >
                    <Typography component='h6'
                        sx={{
                            fontSize:'3.2rem',
                            fontWeight:'bold',
                            mb:'1rem'
                        }}
                    >
                        More Insights
                    </Typography>
                    <DMLink
                        text='View all Insights'
                        link='/insights'
                        small
                        hoverColor='#fff'
                    />
                    <Grid container spacing={6}
                        sx={{
                            mt:'2rem'
                        }}
                    >
                        {moreInsights && Object.values(moreInsights).map((insight, i)=>{
                            return(
                                <Grid item xs={12} md={6}
                                    key={i}
                                    sx={{
                                        height:'600px'
                                    }}
                                >
                                    <InsightPreview insight={insight}/>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
}

export default MoreInsights;