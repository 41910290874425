import { Container, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MoreInsights from "./MoreInsights";
import parse from 'html-react-parser';
import { MetaContext } from "../context/MetaContext";

function SingleInsight(){

    const navigate=useNavigate();
    const meta=useContext(MetaContext);

    //Set html background color
    useEffect(()=>{
        document.body.style.backgroundColor='#fff'
    },[]);

    const mobile=useMediaQuery(theme=>theme.breakpoints.down('md'));
    const {slug}=useParams();

    const [insight,setInsight]=useState();
    const [content, setContent]=useState();

    //Fetch insight by slug
    useEffect(() => {
		axios.get(
            process.env.REACT_APP_BACKEND+
            '/api/insights?filters[slug][$eq]='+slug+
            '&populate=deep'
        ).then((response) => {
            if(response.data.data.length){
                setInsight(response.data.data[0]?.attributes);
                meta.setMeta(response.data.data[0]?.attributes.seo);
            }
            else{
                navigate('/404');
            }
		});
	}, [slug]);

    //Replace image srcs
    useEffect(()=>{
        if(insight){
            const parser = new DOMParser();
            const tempContent = parser.parseFromString(insight.content, 'text/html');
            const images = tempContent.querySelectorAll('img');
            images.forEach((img)=>{
                const oldSrc=img.getAttribute('src');
                img.src=process.env.REACT_APP_BACKEND+oldSrc;
            });
            setContent(tempContent.body.innerHTML); 
        }
    },[insight]);

    function formatDate(date){
		const thedate=new Date(date);
        const d=thedate.getDate();
		const m=thedate.toLocaleString('default', { month: 'long' });
		const y=thedate.getFullYear();
		return d+' '+m+' '+y;
	}

    //Set header colour
	useEffect(()=>{
		window.dispatchEvent(new CustomEvent('headerColorChange',{detail:{color:'#01153C'}}));
	},[]);

    return(
        <Box
            className='dm-section'
			data-color='#01153C'
        >
            {insight?
                <>
                    <Container maxWidth='xl'>
                        <Box
                            sx={{
                                p:{
                                    xs:'1rem 0',
                                    md:'4rem 0'
                                },
                                color:theme=>theme.palette.blue,
                            }}
                        >
                            <Box
                                sx={{
                                    m:'8rem 0 4rem 0',
                                    display:'flex',
                                    flexDirection:'column',
                                    alignItems:'center',
                                    textAlign:'center'
                                }}
                            >
                                <Typography variant='h5' component='h5'
                                    sx={{
                                        color:theme=>theme.palette.yellow,
                                    }}
                                >
                                    {formatDate(insight?.publishedAt)}
                                </Typography>
                                <Typography variant={ mobile? 'h3' : 'h2' } component='h1'
                                    sx={{
                                        maxWidth:'1000px'
                                    }}
                                >
                                    {insight.title}
                                </Typography>
                                <Box
                                    sx={{
                                        height:'6rem',
                                        width:'6rem',
                                        borderRadius:'6rem',
                                        backgroundImage:'url('+process.env.REACT_APP_BACKEND+insight?.author?.data?.attributes?.image?.data?.attributes?.url+')',
                                        backgroundSize:'cover',
                                        backgroundPosition:'center',
                                        m:'2rem 0 0 0'
                                    }}
                                />
                                <Typography variant='h5' component='h5'
                                    sx={{
                                        mt:'0.5rem'
                                    }}
                                >
                                    by {insight?.author?.data?.attributes?.name}
                                </Typography>
                                <Typography variant='p' component='p'
                                    sx={{
                                        mt:'0.5rem'
                                    }}
                                >
                                    Read time: {insight?.readtime}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    position:'relative',
                                    height:'600px',
                                    backgroundImage:'url('+process.env.REACT_APP_BACKEND+insight?.thumbnail?.data?.attributes?.url+')',
                                    backgroundSize:'cover',
                                    backgroundPosition:'center',
                                }}
                            />
                        </Box>
                    </Container>
                    <Container maxWidth='lg'>
                        <Box
                            className='html-section'
                            sx={{
                                mb:'8rem'
                            }}
                        >
                            {content?
                                parse(content)
                            :
                                null
                            }
                        </Box>
                    </Container>
                    <MoreInsights insight={insight}/>
                </>
            :
                <Box
                    sx={{
                        height:'100vh'
                    }}
                />
            }
        </Box>
    );
}

export default SingleInsight;