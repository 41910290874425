import React from 'react';
import { Box, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link as RouterLink } from 'react-router-dom';

function DMLink({ text, link, color, hoverColor, yellow = false, white = false, target='_self', small=false }) {
    return (
        <Box
            className="hover"
            sx={{
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                zIndex: 1,
            }}
        >
            <Typography
                component={RouterLink}
                to={link}
                target={target}
                sx={{
                    fontSize: small? '1.2rem' : '1.6rem',
                    fontWeight:'bold',
                    display: 'flex',
                    alignItems: 'center',
                    color: theme => color ? color : yellow ? theme.palette.yellow : white ? '#fff' : theme.palette.blue,
                    '&:hover': {
                        color: hoverColor ? hoverColor : yellow ? '#fff' : theme => theme.palette.yellow
                    }
                }}
            >
                {text}
                <ArrowForwardIcon
                    sx={{
                        m: '0.1rem 0 0 0.5rem'

                    }}
                />
            </Typography>
        </Box>
    );
}

export default DMLink;