import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useVisible } from 'react-hooks-visible';
import { Link as RouterLink } from 'react-router-dom';

function HomeClients() {

	const mobile = useMediaQuery(theme => theme.breakpoints.down('md'));

	const [clients, setClients] = useState();

	//Fetch clients
	useEffect(() => {
		axios.get(`${process.env.REACT_APP_BACKEND}/api/clients?populate=deep`).then((response) => {
			setClients(response.data.data);
		});
	}, []);

	const [ref, visible] = useVisible((vi) => vi > 0.5);
	const [display, setDisplay] = useState(false);

	//Fire fade in animation only once
	useEffect(() => {
		if (visible && !display) {
			setDisplay(true);
		}
	}, [visible]);

	return (
		<Box
			className="dm-section"
			data-color='#FFF'
			ref={ref}
			sx={{
				color: 'white',
				backgroundColor: theme => theme.palette.blue,
				p: mobile ? '4rem 0' : '8rem 0'
			}}
		>
			<Container maxWidth='xl'>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant={mobile? 'h5' : 'h3'} component='h3'
							sx={{
								textAlign: 'center',
								mb: '4rem',
								color: theme => theme.palette.yellow,
								maxWidth:'550px',
								m:'auto'
							}}
						>
							We’re proud <span style={{ color: '#fff' }}>to work with</span> amazing companies <span style={{ color: '#fff' }}>and</span> people
						</Typography>
					</Grid>
					{clients && clients.map((client, i) => {
						return (
							<Grid item xs={6} sm={6} md={4} lg={3}
								key={i}
								sx={{
									aspectRatio: '1/0.5',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								{client.attributes.logo.data ?
									<img
										src={process.env.REACT_APP_BACKEND + client.attributes.logo.data?.attributes.url}
										alt={client.attributes.name}
										style={{
											height: client.attributes.tall_logo ? mobile ? '50px' : '100px' : mobile ? '30px' : '60px',
											margin: '1rem',
											opacity: display ? 1 : 0,
											transition: 'opacity 0.5s',
											transitionDelay: `${i * 0.1}s`
										}}
									/>
									:
									<Typography variant='h4'
										sx={{
											opacity: display ? 1 : 0,
											transition: 'opacity 0.5s',
											transitionDelay: `${i * 0.1}s`
										}}
									>
										{client.attributes.name}
									</Typography>
								}
							</Grid>
						)
					})}
					<Grid item xs={12}>
						<Box
							sx={{
								mt: '3rem',
								display: 'flex',
								justifyContent: 'center',
								color: '#fff'
							}}
						>
							<Typography variant={mobile? 'h6' : 'h5'}
								component={RouterLink}
								to='/contact'
								sx={{
									fontWeight:'bold',
									textAlign:{
										xs:'center',
										md:'left'
									},
									maxWidth:{
										xs:'260px',
										md:'none'
									}
								}}
							>
								<span style={{ color: '#FFCD00' }}>You'll be in good company,</span> start your journey today
								<ArrowForwardIcon sx={{ translate: '6px 6px' }} />
							</Typography>
						</Box>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
}

export default HomeClients;