import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { useVisible } from 'react-hooks-visible';
import DMLink from '../layout/DMLink';
import { Parallax } from 'react-scroll-parallax';
import parse from 'html-react-parser';

function HomeServices({ data, lockScroll }) {

	//console.log(data?.service_list)

	const mobile = useMediaQuery(theme => theme.breakpoints.down('md'));

	//Services list fade in animation
	const [servicesRef, servicesVisible] = useVisible((vi) => vi > (mobile ? 0.25 : 0.5));
	const [displayServices, setDisplayServices] = useState(false);
	useEffect(() => {
		if (servicesVisible && !displayServices) {
			setDisplayServices(true);
		}
	}, [servicesVisible]);

	return(
		<Container
			maxWidth='xl'
			className="dm-section"
			data-color='#01153c'
		>
			<Box
				sx={{
					//display: lockScroll? 'none' : 'block',
					p: mobile ? '4rem 0' : '8rem 0',
					color: theme => theme.palette.blue,
				}}
			>
				{data &&
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<Typography variant={mobile? 'h3' : 'h2'} component='h2'>
								What we do
							</Typography>
							<Typography variant='p' component='p'
								sx={{
									m: mobile ? '3rem 0' : '3rem 3rem 3rem 0'
								}}
							>
								{data.servicesparagraph}
							</Typography>
							<Box
								ref={servicesRef}
							>
								{data.service_list.map((service, i) =>{
									return(
										<Box
											key={i}
											sx={{
												mb:'3rem',
												opacity: displayServices? 1 : 0,
												transition:'opacity 0.5s',
												transitionDelay: i*0.25+'s'
											}}
										>
											<Typography
												variant='h5'
												component={service.heading_link? 'a' : 'span'}
												href={service.heading_link}
												sx={{
													textDecoration: service.heading_link? 'underline' : 'none',
												}}
											>
												{service.heading}
											</Typography>
											<Box
												className='html-section'
												sx={{
													mt:'0.5rem',
													maxWidth:'500px',
												}}
											>
												{parse(service.paragraph)}
											</Box>
										</Box>
									);
								})}
								<Box
									sx={{
										opacity: displayServices ? 1 : 0,
										transition: 'opacity 0.5s',
										transitionDelay: `${data.service_list.length * 0.25}s`,
										mb: mobile ? '4rem' : 0
									}}
								>
									<DMLink
										text='Our Services'
										link='/services'
									/>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12} md={6}>
							<Parallax
								speed={-5}
								style={{
									position: 'relative',
									width: '60%',
									left: '40%'
								}}
							>
								<img
									src={process.env.REACT_APP_BACKEND + data.servicesmedia.data[0].attributes.url}
									alt={data.servicesmedia.data[0].attributes.alternativeText}
									width='100%'
								/>
							</Parallax>
							<Parallax
								speed={5}
								style={{
									position: 'relative',
									width: '70%',
									marginTop: '-25%',
									zIndex: 1
								}}
							>
								<img
									src={process.env.REACT_APP_BACKEND + data.servicesmedia.data[1].attributes.url}
									alt={data.servicesmedia.data[1].attributes.alternativeText}
									width='100%'
								/>
							</Parallax>
							<Parallax
								speed={10}
								style={{
									position: 'relative',
									width: '50%',
									left: '40%',
								}}
							>
								<img
									src={process.env.REACT_APP_BACKEND + data.servicesmedia.data[2].attributes.url}
									alt={data.servicesmedia.data[2].attributes.alternativeText}
									width='100%'
								/>
							</Parallax>
						</Grid>
					</Grid>
				}
			</Box>
		</Container>
	);
}

export default HomeServices;