import { Box, Container, Grid, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

function AboutValues({ aboutData }) {

    const mobile = useMediaQuery(theme => theme.breakpoints.down('md'));

    const [activeValue, setActiveValue] = useState(0);
    const containerRef = useRef(null);

    //Track vertical scroll position of 'values' container
    useEffect(() => {

        function handleScroll() {

            //Get offsets of each value
            const values = Array.from(containerRef.current.children);
            values.forEach((value, i) => {
                const { top } = value.getBoundingClientRect();
                //Set active value
                if (top <= window.innerHeight / 2) {
                    setActiveValue(i);
                }
            });
        }

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    }, []);

    return (
        <Box
            sx={{
                backgroundColor: '#FFF'
            }}
        >
            <Container
                maxWidth='xl'
                className="dm-section"
                data-color={'#01153c'}
            >
                <Box
                    sx={{
                        p: mobile ? '8rem 0' : '16rem 0',
                        color: theme => theme.palette.blue,
                    }}
                >
                    <Typography variant={mobile? 'h4' : 'h3'} component='h3' sx={{ color: theme => theme.palette.yellow, fontWeight:'bold' }}>
                        Our values
                    </Typography>
                    <Typography variant={mobile? 'h3' : 'h2'} component='h2'>
                        are your values
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}
                            ref={containerRef}
                        >
                            {aboutData?.values && Object.values(aboutData.values).map((value, i) => {
                                return (
                                    <Box
                                        key={i}
                                        sx={{
                                            m: mobile ? '4rem 0 0 0' : '16rem 0 32rem 0',
                                            opacity: i === activeValue ? 1 : 0.5,
                                            transition: 'opacity 0.5s',
                                        }}
                                    >
                                        <Typography variant={mobile? 'h4' : 'h3'} component='h3'
                                            sx={{
                                                fontWeight:'bold'
                                            }}
                                        >
                                            <span
                                                style={{
                                                    color: '#F7DA64',
                                                    fontSize: '1.5rem',
                                                    marginRight: '0.5rem'
                                                }}
                                            >
                                                {i + 1}.
                                            </span>
                                            {value.title}
                                        </Typography>
                                        <Typography variant='p' component='p' sx={{ m: '1rem 0 4rem 2rem' }}>
                                            {value.paragraph}
                                        </Typography>
                                    </Box>
                                );
                            })}
                        </Grid>
                        {!mobile &&
                            <Grid item xs={12} md={6}>
                                <Box
                                    sx={{
                                        position: 'relative',
                                        height: '100%',
                                        display:'flex',
                                        flexDirection:'column',
                                        alignItems:'center'
                                    }}
                                >
                                    {aboutData?.values && Object.values(aboutData.values).map((value, i) => {
                                        return (
                                            <Typography
                                                key={i}
                                                sx={{
                                                    position:'sticky',
                                                    top:'25vh',
                                                    marginTop: 'calc(' + i + ' * -50vh)',
                                                    opacity: i === activeValue ? 1 : 0,
                                                    transition:'opacity 0.5s',
                                                    fontSize:'33.333vh',
                                                    fontWeight:600,
                                                    fontFamily:'din-2014',
                                                    color:theme=>theme.palette.yellow
                                                }}
                                            >
                                                {aboutData.values[i].title.split('')[0]}
                                            </Typography>
                                        )
                                    })}
                                </Box>
                            </Grid>
                        }
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
}

export default AboutValues;