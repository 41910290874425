import React, { createContext, useState } from "react";
import { Helmet } from "react-helmet";

export const MetaContext = createContext();

export function MetaProvider(props) {

    const [meta,setMeta]=useState({
        metaTitle:'',
        metaDescription:''
    });

    //console.log(meta)

    return (
        <MetaContext.Provider value={{ setMeta }}>
                
            <Helmet>

                <meta charSet='utf-8'/>

                <meta name='viewport' content='width=device-width, initial-scale=1.0'></meta>

                <title>{meta?.metaTitle}</title>

                <meta name='description' content={meta?.metaDescription}/>
                
            </Helmet>
            
            {props.children}

        </MetaContext.Provider>
    );
};