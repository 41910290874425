import { Container, Fade, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";

function ServicesHeader({ pageData }) {

    const mobile = useMediaQuery(theme => theme.breakpoints.down('md'));
    const keywords = pageData.keywords;
    const [currentKeyword, setCurrentKeyword] = useState(0);
    const [left, setLeft] = useState('0%');

    useEffect(() => {

        const animation = setInterval(() => {
            const elapsed = Date.now() % 3500;
            if (elapsed > 2500) {
                setLeft('-200%');
            }
            if (elapsed > 3000) {
                setCurrentKeyword((currentKeyword) => (currentKeyword + 1) % keywords.length);
                setLeft('-0%');
            }

        }, 500)
        return () => clearInterval(animation);

    }, []);

    return (
        <Container
            maxWidth='xl'
            className="dm-section"
            data-color={'#01153C'}
        >
            <Box
                sx={{
                    overflowX: 'clip',
                    p: mobile ? '6rem 0' : '8rem 0'
                }}
            >
                {pageData &&
                    <Typography variant='h1' component='h1'
                        sx={{
                            lineHeight: mobile ? '3rem' : '8rem'
                        }}
                    >
                        <span
                            style={{
                                color: '#FFCD00',
                                fontSize: mobile ? '3rem' : '4rem',
                            }}
                        >
                            {pageData.h1tagline}
                        </span>
                        <br />
                        <span
                            style={{
                                color: '#01153C',
                                position: 'relative',
                                fontSize: mobile ? '4rem' : '8rem'
                            }}
                        >
                            {pageData.keywords[currentKeyword].keyword}
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: left,
                                    transition: 'left 0.5s',
                                    width: '300%',
                                    height: '100%',
                                    background: 'linear-gradient(to right, transparent 33.3%, white 66.6%)',
                                }}
                            />
                        </span>
                    </Typography>
                }
            </Box>
        </Container>
    )
}

export default ServicesHeader;