import { Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useVisible } from "react-hooks-visible";

export default function Keypoints({ keypoints }){

    //console.log(keypoints)

    const mobile = useMediaQuery(theme => theme.breakpoints.down('md'));

    const [ref, visible] = useVisible((vi) => vi > 0.5);
    const [display, setDisplay] = useState(false);

    //Fire fade in animation only once
	useEffect(() => {
		if (visible && !display) {
			setDisplay(true);
		}
	}, [visible]);

    return(
        <Box
            className="dm-section"
            data-color='#fff'
            sx={{
                backgroundColor: theme => theme.palette.blue
            }}
        >
            {keypoints.image.data &&
                <Box
                    sx={{
                        height:'50vh',
                        width:'100vw',
                        backgroundImage:'url('+process.env.REACT_APP_BACKEND+keypoints.image.data.attributes.url+')',
                        backgroundSize:'cover',
                        backgroundPosition:'center',
                        backgroundAttachment:{
                            xs:'scroll',
                            md:'fixed'
                        }
                    }}
                />
            }
            <Container maxWidth='xl'>
                <Box
                    sx={{
                        p:{
                            xs:'4rem 0',
                            md:'8 rem 0'
                        },
                        color: '#fff'
                    }}
                >
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Typography variant={mobile ? 'h4' : 'h3'} component='h3'
                                sx={{
                                    color:theme=>theme.palette.yellow,
                                    mb:'2rem'
                                }}
                            >
                                {keypoints.heading}
                            </Typography>
                        </Grid>
                        {Object.values(keypoints.points).map((keypoint, i) => {
                            return(
                                <Grid 
                                    item xs={12} sm={6} md={12/keypoints.points.length} 
                                    key={i} 
                                    ref={i===0? ref : null}
                                    sx={{
                                        opacity: display? 1 :0,
                                        transition:'opacity 0.5s',
                                        transitionDelay: i*0.25+'s'
                                    }}
                                >
                                    {keypoint.icon.data &&
                                        <Box
                                            sx={{
                                                height:50,
                                                background:theme=>theme.palette.yellow,
                                                mask:'url('+process.env.REACT_APP_BACKEND+keypoint.icon.data.attributes.url+') no-repeat left',
                                            }}
                                        />
                                    }
                                    <Typography variant='h4' component='h4'
                                        sx={{
                                            mt:'1rem',
                                            fontWeight:700
                                        }}
                                    >
                                        {keypoint.title}
                                    </Typography>
                                    <Typography variant='p' component='p'
                                        sx={{
                                            m: '1rem 0'
                                        }}
                                    >
                                        {keypoint.text}
                                    </Typography>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
}