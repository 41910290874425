import React, { useEffect, useState } from 'react';
import { Container, Typography } from '@mui/material';
import { Box } from '@mui/system';
import DMLink from '../layout/DMLink';
import { useVisible } from 'react-hooks-visible';

function HomeShowcase({ data, showcase }) {

	const aspectRatio = window.innerWidth / window.innerHeight;

	//Fade in animation
	const [ref, visible] = useVisible((vi) => vi > 0.5);
	const [display, setDisplay] = useState(false);
	useEffect(() => {
		if (visible && !display) {
			setDisplay(true);
		}
	}, [visible]);

	//console.log(showcase)

	return (
		<Box
			className="dm-section"
			data-color='#FFF'
			sx={{
				position: 'relative',
				height: '100vh',
				overflow: 'hidden',
			}}
		>
			{showcase &&
				<>
					{showcase.video.data?
						<video
							autoPlay
							loop
							muted
							playsInline
							height={aspectRatio < 1.78 ? '100%' : ''}
							width={aspectRatio >= 1.78 ? '100%' : ''}
							style={{
								position: 'absolute',
								left: '50%',
								transform: 'translate(-50%,0)'
							}}
						>
							<source src={process.env.REACT_APP_BACKEND + showcase.video.data.attributes.url} />
						</video>
					:
						<img
							src={process.env.REACT_APP_BACKEND+showcase.images.data[0].attributes.url}
							alt={showcase.images.data[0].attributes.alternativeText}
							style={{
								width:'100%',
								height:'100%',
								objectFit:'cover',
								objectPosition:'center'
							}}
						/>
					}
					<Box
						sx={{
							position: 'absolute',
							width: '100%',
							height: '100%',
							top: 0,
							//backgroundColor: 'rgba(0,0,0,0.25)',
							background:'linear-gradient(90deg, black 0%, transparent 100%)'
						}}
					>
						<Container maxWidth='xl'
							sx={{
								height: '100%',
								width: '100%',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								color: 'white',
							}}
						>
							<Typography variant='h6' component='p'
								sx={{
									opacity: display ? 1 : 0,
									transition: 'opacity 1s',
									fontWeight:'bold'
								}}
							>
								Showcase
							</Typography>
							<Typography variant='h3' component='h3'
								sx={{
									mt: '1rem',
									opacity: display ? 1 : 0,
									transition: 'opacity 0.5s',
									transitionDelay: '0.2s'
								}}
							>
								{showcase.title}
							</Typography>
							<Typography variant='p' component='p'
								sx={{
									maxWidth: '500px',
									m: '2rem 0',
									opacity: display ? 1 : 0,
									transition: 'opacity 0.5s',
									transitionDelay: '0.4s',
									whiteSpace:'break-spaces'
								}}
							>
								{data.showcase_summary}
							</Typography>
							<Box
								ref={ref}
								sx={{
									opacity: display ? 1 : 0,
									transition: 'opacity 0.5s',
									transitionDelay: '0.6s'
								}}
							>
								<DMLink
									yellow
									text='View our work'
									link='/showcases'
								/>
							</Box>
						</Container>
					</Box>
				</>
			}
		</Box>
	);
}

export default HomeShowcase;