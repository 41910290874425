import { Box, Container, Fade, Grid, Typography, useMediaQuery } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link as RouterLink } from "react-router-dom";
import { useEffect, useState } from "react";
import DMLink from "../layout/DMLink";
import { useVisible } from "react-hooks-visible";
import parse from 'html-react-parser';

export default function MoreServices({ services }){

    //console.log(services)

    const mobile = useMediaQuery(theme => theme.breakpoints.down('md'));

    const [currentService, setCurrentService]=useState(0);

    //List Animation
    const [ref, visible] = useVisible((vi) => vi > 0.5);
    const [displayList, setDisplayList] = useState(false);
    useEffect(() => {
        if (visible && !displayList) {
            setDisplayList(true);
        }
    }, [visible]);

    return(
        <Box
            className="dm-section"
            data-color='#01153C'
            sx={{
                pt:{
                    xs:'8rem',
                    md:'12rem'
                },
                pb:{
                    xs:'4rem',
                    md:'8rem',
                },
                background:theme=>theme.palette.yellow,
                color:theme=>theme.palette.blue
            }}
        >
            <Container maxWidth='xl'>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}
                        ref={ref}
                    >
                        <Typography variant={mobile? 'h4' : 'h3'}>
                            More Services
                        </Typography>
                        {services?.map((service, i)=>{
                            return(
                                <Box
                                    key={i}
                                    onMouseEnter={()=>setCurrentService(i)}
                                    sx={{
                                        m: '2rem 0',
                                        opacity: displayList ? 1 : 0,
                                        transition: 'opacity 0.25s',
                                        transitionDelay: `${i * 0.1}s`,
                                    }}
                                >
                                    <Typography variant='h4'
                                        key={i}
                                        component={RouterLink}
                                        to={'/services/'+service.attributes.slug}
                                        sx={{
                                            display: 'block',
                                            fontSize:{
                                                xs:'1.6rem',
                                                md:'2rem'
                                            },
                                            fontWeight: 700,
                                            display: 'flex',
                                            alignItems: 'center',
                                            opacity: mobile? 1 : currentService===i? 1 : 0.5,
                                            transition:'opacity 0.25s',
                                            '&:hover .MuiSvgIcon-root': {
                                                opacity: '1',
                                                marginLeft: '1rem'
                                            },
                                        }}
                                    >
                                        {service.attributes.name}
                                        <ArrowForwardIcon fontSize='large'
                                            sx={{
                                                opacity: '0',
                                                transition: 'opacity 0.25s, margin-left 0.25s'
                                            }}
                                        />
                                    </Typography>
                                </Box>
                            )
                        })}
                    </Grid>
                    {!mobile &&
                        <Grid item xs={6}>
                            {services?.map((service, i)=>{
                                if(currentService===i){
                                    return(
                                        <Fade in={currentService===i} key={i}>
                                            <Box>
                                                <img
                                                    src={process.env.REACT_APP_BACKEND + service.attributes.thumbnail.data.attributes.url}
                                                    alt={service.attributes.thumbnail.data.attributes.alternativeText}
                                                    draggable={false}
                                                    style={{
                                                        width:'100%',
                                                        height:'400px',
                                                        objectFit:'cover',
                                                        objectPosition:'center'
                                                    }}
                                                />
                                                <Box
                                                    className='html-section'
                                                    sx={{
                                                        m:'2rem 0'
                                                    }}
                                                >
                                                    {parse(services[currentService].attributes.preview_summary)}
                                                </Box>
                                                <DMLink
                                                    text='Learn More'
                                                    link={'/services/'+services[currentService].attributes.slug}
                                                    hoverColor='#fff'
                                                />
                                            </Box>
                                        </Fade>
                                    );
                                }
                            })}
                        </Grid>
                    }
                </Grid>
            </Container>
        </Box>
    );
}