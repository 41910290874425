import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import axios from 'axios';
import { Container, Grid, Link, Typography, useMediaQuery } from '@mui/material';
import Logo from './Logo';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import DMLink from './DMLink';
import { Link as RouterLink } from 'react-router-dom';
import { WebsiteCarbonBadge } from 'react-websitecarbon-badge';

function Footer() {

	const mobile = useMediaQuery(theme => theme.breakpoints.down('md'));

	const [mainMenu, setMainMenu] = useState();

	//Fetch main menu
	useEffect(() => {
		axios.get(`${process.env.REACT_APP_BACKEND}/api/main-menu?populate=deep`).then((response) => {
			setMainMenu(response.data.data.attributes.blocks);
		});
	}, []);

	return (
		<Box
			id="footer"
			sx={{
				color: 'white',
				backgroundColor: theme => theme.palette.blue,
				p:'4rem 0'
			}}
			className="dm-section"
			data-color='#FFCD00'
		>
			<Container maxWidth='xl'>
				<Grid container spacing={2}>
					<Grid item xs={12} md={6}>
						<Typography
							sx={{
								fontSize:'3.2rem',
								fontWeight:700
							}}
						>
							Your Project?
						</Typography>
						<Typography
							sx={{
								mt:'-0.5rem',
								fontSize:'2rem',
								fontWeight:700,
								color:theme=>theme.palette.yellow
							}}
						>
							Let's talk.
						</Typography>
						<Box
							sx={{
								mt: '2rem',
							}}
						>
							<DMLink
								white
								text='Get in touch'
								link='/contact'
								small
							/>
						</Box>
					</Grid>
					<Grid item xs={12} md={6}>
						<Box
							sx={{
								width: 'fit-content',
							}}
						>
							<Typography variant='h6' component='p' sx={{ fontWeight:600 }}>
								Unit 5, New Barn Farm Offices<br />Tadlow, Royston<br />Cambridgeshire<br />SG8 0EP
							</Typography>
							<br /><br />
							<Box
								sx={{
									display: 'flex',
									flexDirection: mobile ? 'column' : 'row',
								}}
							>
								<Link
									href='tel:+441223398156'
									aria-label='Contact us by phone'
									sx={{
										display: 'flex',
										color: 'white',
										'&:hover': { color: theme => theme.palette.yellow },
										cursor: 'pointer'
									}}
								>
									<PhoneIcon sx={{ m: '0.4rem 0.5rem -0.5rem 0' }} />
									<Typography variant='h6' component='p' sx={{ mr: '1.5rem' }}>
										01223 398 156
									</Typography>
								</Link>
								<Link
									href='mailto:hello@design-monkey.co.uk'
									aria-label='Contact us by email'
									sx={{
										display: 'flex',
										color: 'white',
										'&:hover': { color: theme => theme.palette.yellow },
										cursor: 'pointer'
									}}
								>
									<MailIcon sx={{ m: '0.4rem 0.5rem -0.5rem 0' }} />
									<Typography variant='h6' component='p'>
										hello@design-monkey.co.uk
									</Typography>
								</Link>
							</Box>
						</Box>
					</Grid>
				</Grid>
				<Box
					sx={{
						backgroundColor: '#203253',
						height: '2px',
						width: '100%',
						m: mobile ? '2rem 0' : '4rem 0'
					}}
				/>
				<Grid container spacing={2} direction={mobile ? 'column-reverse' : 'row'}>
					<Grid item xs={12} md={6}>
						<Box
							sx={{
								display:'flex',
								flexDirection:'column'
							}}
						>
							<Box>
								<Logo color={'#fff'} />
							</Box>
							<Box
								sx={{
									mt:'1rem',
									display:'flex',
									alignItems:'center'
								}}
							>
								{mainMenu && Object.values(mainMenu).map((block) => {
									if (['terms', 'privacy'].includes(block.slug)) {
										return (
											<Typography
												key={block.title}
												component={RouterLink}
												to={'/'+block.slug}
												sx={{
													mr: '1rem',
													fontSize:'1.2rem',
													fontWeight: 700,
													color: '#fff',
													'&:hover': {
														color: theme => theme.palette.yellow
													}
												}}
											>
												{block.title}
											</Typography>
										)
									}
								})}
								<Typography variant='p' component='p' sx={{ color: 'white' }}>
									© Copyright {new Date().getFullYear()}
								</Typography>
							</Box>
						</Box>
					</Grid>
					<Grid item xs={12} md={6}>
						<Box
							sx={{
								display: 'flex',
								justifyContent: mobile ? 'left' : 'right',
								flexWrap: 'wrap',
								m: mobile ? '0 0 0 -0.5rem' : '0 -0.5rem 0 0'
							}}
						>
							{mainMenu && Object.values(mainMenu).map((block) => {
								if (!['terms', 'privacy'].includes(block.slug)) {
									return (
										<Typography
											key={block.title}
											component={RouterLink}
											to={'/'+block.slug}
											sx={{
												mr: '1rem',
												fontSize:'1.2rem',
												fontWeight: 700,
												color: '#fff',
												'&:hover': {
													color: theme => theme.palette.yellow
												}
											}}
										>
											{block.title}.
										</Typography>
									)
								}
							})}
						</Box>
						<Box
							sx={{
								mt: '2rem',
								mb: mobile ? '2rem' : 0,
								display: 'flex',
								alignItems:'center',
								justifyContent: mobile ? 'flex-start' : 'end',
								'& .carbonbadge':{
									scale:'0.75',
									marginRight:'-1rem'
								}
							}}
						>
							<a 
								href="https://ecologi.com/designmonkeymedialtd?r=644c2ae248beaa40346fbddc" 
								title="View our Ecologi profile" 
								aria-label="View our Ecologi profile" 
								target="_blank" 
								rel="noopener noreferrer" 
								style={{ width: '150px', display: 'inline-block' }}
							>
								<img alt="We offset our carbon footprint via Ecologi" src="https://ecologi-assets.imgix.net/badges/climate-positive-workforce-white-landscape.png" style={{ width: '150px' }} />
							</a>
							{/* <WebsiteCarbonBadge
								dark
								url="design-monkey.co.uk"
							/> */}
						</Box>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
}

export default Footer;