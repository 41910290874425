import { Button, Checkbox, FormControlLabel, Grid, TextField, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTheme } from "@emotion/react";
import axios from "axios";

export default function ContactForm({ setSuccess }) {

    const theme = useTheme();

    const formik = useFormik({
        initialValues: {
            fullName: '',
            companyName: '',
            email: '',
            phone: '',
            message: '',
            agree: false,
        },
        validationSchema: Yup.object({
            fullName: Yup.string().required('Please enter your full name'),
            companyName: Yup.string().required('Please enter your company name'),
            email: Yup.string().email('Invalid email address').required('Please enter your email'),
            phone: Yup.number().typeError('Please enter a valid phone number').required('Please enter your phone number'),
            message: Yup.string().required('Please enter your message'),
            agree: Yup.boolean().required('Required').oneOf([true], 'You must agree with Design Monkey\'s privacy policy.'),
        }),
        onSubmit: values => {
            //alert(JSON.stringify(values, null, 2));
            axios.post(
                process.env.REACT_APP_BACKEND+'/api/contact',
                values
            ).then((response)=>{
                //console.log(response);
                if(response.data==="success"){
                    setSuccess(true);
                }
            }).catch((error)=>{
                console.error(error);
            });
        },
    });

    const mobile = useMediaQuery(theme.breakpoints.down('md'));

    const textFieldStyle = {
        color: '#fff !important',
        backgroundColor: 'transparent !important',
        width: '100%',
        '& div': {
            color: '#fff',
            fontSize: '1.25rem',
            p: '0.5rem 0rem',
            border: 'none'
        },
        '& div:after': {
            border: 'none'
        },
        '& .MuiInputBase-root': {
            // transition: '0.35s ease all',
            '&:before': {
                borderBottom: 'none !important',
                transition: '0.35s ease all',
                content: '""',
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                height: '2px',
                background: '#fff',
                width: '100%',
                transform: 'scale(1)',
            },
            '&:hover:before': {
                borderBottom: 'none !important',
            },
            '&:after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                height: '2px',
                background: theme.palette.yellow,
                width: '100%',
                transform: 'scale(0)',
                transition: '0.35s ease all',
            },
            '&:hover:after': {
                transform: 'scale(1.0)',
                transition: '0.35s ease all',

            },
            '&:focus:after': {
                transform: 'scale(1.0)',
                transition: '0.35s ease all',

            },
            '&:focus-visible:after': {
                transform: 'scale(1.0)',
                transition: '0.35s ease all',

            },
            '&:focus-visible': {
                outline: 'none',
                border: 0,
                transition: '0.35s ease all',
                '&:after': {
                    transform: 'scale(1.0)',
                    transition: '0.35s ease all',

                }
            },
        },
        '& .Mui-focused': {
            // borderBottom: '1px solid ' + theme.palette.yellow,
            border: 0,
            transition: '0.35s ease all',
            '&:after': {
                transform: 'scale(1.0)',
                transition: '0.35s ease all',
            }
        },
        '&::placeholder': {
            color: '#fff'
        }

    }
    const textAreaStyle = {
        color: '#fff',
        width: '100%',
        mt: '2rem',
        '& div': {
            color: '#fff',
            fontSize: '1.25rem',
            p: '0.5rem 1rem'
        },
        '& div:after': {
            border: 'none'
        },
        '& .MuiInputBase-root': {
            border: '2px solid #fff',
            '&:hover': {
                border: '2px solid ' + theme.palette.yellow,
                transition: '0.35s ease all',
            },
        },
        '& .Mui-focused': {
            border: '2px solid ' + theme.palette.yellow,
            transition: '0.35s ease all',
        },
    }

    return (
        <form onSubmit={formik.handleSubmit}
            style={{
                width: '100%',
                display: 'flex',
                flexWrap: 'wrap',
            }}>
            <Grid container spacing={2}
                sx={{
                    maxWidth: '100%',
                    width: '100%',
                    ml: 0,
                }}>

                <Grid item xs={6}>
                    <TextField
                        variant='standard'
                        placeholder='Full Name'
                        name="fullName"
                        sx={[textFieldStyle, { color: '#fff !important', backgroundColor: 'transparent !important' }]}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.fullName}
                        inputProps={
                            {
                                sx: {
                                    '&::placeholder': {
                                        color: '#fff',
                                        opacity: 1, // otherwise firefox shows a lighter color
                                    },
                                },
                            }
                        }
                    />
                    {formik.touched.fullName && formik.errors.fullName ? (
                        <Typography
                            sx={{
                                color: theme.palette.yellow,
                                fontSize: '0.95rem',
                            }}
                        >{formik.errors.fullName}
                        </Typography>
                    ) : null}
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        variant='standard'
                        placeholder='Company Name'
                        name="companyName"
                        sx={[textFieldStyle, { color: '#fff !important', backgroundColor: 'transparent !important' }]}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.companyName}
                        inputProps={
                            {
                                sx: {
                                    '&::placeholder': {
                                        color: '#fff',
                                        opacity: 1, // otherwise firefox shows a lighter color
                                    },
                                },
                            }
                        }
                    />
                    {formik.touched.companyName && formik.errors.companyName ? (
                        <Typography
                            sx={{
                                color: theme.palette.yellow,
                                fontSize: '0.95rem',
                            }}
                        >{formik.errors.companyName}
                        </Typography>
                    ) : null}
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant='standard'
                        placeholder='Email'
                        name="email"
                        sx={[textFieldStyle, { color: '#fff !important', backgroundColor: 'transparent !important' }]}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        inputProps={
                            {
                                sx: {
                                    '&::placeholder': {
                                        color: '#fff',
                                        opacity: 1, // otherwise firefox shows a lighter color
                                    },
                                },
                            }
                        }
                    />
                    {formik.touched.email && formik.errors.email ? (
                        <Typography
                            sx={{
                                color: theme.palette.yellow,
                                fontSize: '0.95rem',
                            }}
                        >{formik.errors.email}
                        </Typography>
                    ) : null}
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant='standard'
                        placeholder='Phone'
                        name="phone"
                        sx={[textFieldStyle, { color: '#fff !important', backgroundColor: 'transparent !important' }]}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phone}
                        inputProps={
                            {
                                sx: {
                                    '&::placeholder': {
                                        color: '#fff',
                                        opacity: 1, // otherwise firefox shows a lighter color
                                    },
                                },
                            }
                        }
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                        <Typography
                            sx={{
                                color: theme.palette.yellow,
                                fontSize: '0.95rem',
                            }}
                        >{formik.errors.phone}
                        </Typography>
                    ) : null}
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant='standard'
                        placeholder='Tell us about your project...'
                        name="message"
                        multiline
                        minRows={6}
                        sx={[textAreaStyle, { color: '#fff !important', backgroundColor: 'transparent !important' }]}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.message}
                        inputProps={
                            {
                                sx: {
                                    '&::placeholder': {
                                        color: '#fff',
                                        opacity: 1, // otherwise firefox shows a lighter color
                                    },
                                },
                            }
                        }
                    />
                    {formik.touched.message && formik.errors.message ? (
                        <Typography
                            sx={{
                                color: theme.palette.yellow,
                                fontSize: '0.95rem',
                            }}
                        >{formik.errors.message}
                        </Typography>
                    ) : null}
                </Grid>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: mobile ? 'column' : 'row',
                            alignItems: 'center'
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                //justifyContent: mobile? 'space-between' : 'left'
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    //justifyContent: mobile? 'space-between' : 'left'
                                }}>
                                <FormControlLabel
                                    value={formik.values.agree}
                                    checked={formik.values.agree}
                                    onChange={formik.handleChange}
                                    name='agree'
                                    control={<Checkbox
                                        size='large'
                                        sx={{
                                            color: '#fff',
                                            borderRadius: '0',
                                            '&.Mui-checked': {
                                                color: theme.palette.yellow
                                            }
                                        }} />}
                                    label={
                                        <Typography>
                                            I agree with Design Monkey’s 
                                            <a 
                                                href="/privacy" 
                                                aria-label="View our privacy policy"
                                                style={{ color: theme.palette.yellow }}
                                            >
                                                privacy policy
                                            </a>
                                        </Typography>
                                    }
                                    sx={{
                                        color: '#fff',
                                        '& .MuiCheckbox-root': {
                                            padding: theme.spacing(.5)
                                        }
                                    }}
                                />

                            </Box>
                            {formik.touched.agree && formik.errors.agree ? (
                                <Typography
                                    sx={{
                                        color: theme.palette.yellow,
                                        fontSize: '0.95rem',
                                    }}
                                >
                                    {formik.errors.agree}
                                </Typography>
                            ) : null}
                            <Button
                                onClick={formik.handleSubmit}
                                sx={{
                                    fontSize: '1.25rem',
                                    fontWeight: 'bold',
                                    p: '0.35rem 3rem',
                                    mt: theme.spacing(2),
                                    width: mobile ? '100%' : 'auto',
                                    backgroundColor: theme.palette.yellow,
                                    color: theme.palette.blue,
                                    border: '1px solid ' + theme.palette.yellow,
                                    borderRadius: '0',
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                    transition: '0.35s ease all',
                                    '&:hover': {
                                        backgroundColor: theme.palette.blue,
                                        color: theme.palette.yellow,
                                        border: '1px solid ' + theme.palette.yellow,
                                        transition: '0.2s ease all'
                                    }
                                }}
                            >
                                Submit
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </form>
    )
}