import { Backdrop, Box, Container, IconButton, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import CloseIcon from '@mui/icons-material/Close';

function SingleShowcaseImages({ showcase }) {

    const mobile=useMediaQuery(theme=>theme.breakpoints.down('md'));

    const [activeImg, setActiveImg] = useState(0);
    const imageContainerRef = useRef(null);
    const [expandedImg, setExpandedImg]=useState(null);

    // Variables to handle drag scrolling
    const isDragging = useRef(false);
    const startX = useRef(0);
    const scrollLeft = useRef(0);

    // Scroll the chosen image into view when clicked (centered)
    function scrollToImg(i) {
        const container = imageContainerRef.current;
        const images = container.children;
        const targetImage = images[i];

        if (targetImage) {
            const containerWidth = container.clientWidth;
            const imageRect = targetImage.getBoundingClientRect();
            const imageWidth = imageRect.width;

            // Calculate the scroll position to center the image
            const offsetLeft = targetImage.offsetLeft;
            const scrollTo = offsetLeft - containerWidth / 2 + imageWidth / 2;

            container.scrollTo({
                left: scrollTo,
                behavior: "smooth",
            });
        }
    }

    //Reset when showcase changes
    useEffect(()=>{
        scrollToImg(0);
    },[showcase]);

    // Track horizontal scroll position of image container
    useEffect(() => {
        function handleScroll() {
            const images = Array.from(imageContainerRef.current.children);
            const containerLeft = imageContainerRef.current.getBoundingClientRect().left;
            const containerRight = imageContainerRef.current.getBoundingClientRect().right;

            const scrollLeft = imageContainerRef.current.scrollLeft;
            const scrollWidth = imageContainerRef.current.scrollWidth;
            const clientWidth = imageContainerRef.current.clientWidth;

            // Check if scrolled all the way to the left
            if (scrollLeft === 0) {
                setActiveImg(0);
                return;
            }

            // Check if scrolled all the way to the right
            if (scrollLeft + clientWidth >= scrollWidth) {
                setActiveImg(images.length - 1);
                return;
            }

            let maxVisibleArea = 0;
            let activeIndex = 0;

            images.forEach((image, i) => {
                const imageRect = image.getBoundingClientRect();
                const imageLeft = imageRect.left;
                const imageRight = imageRect.right;

                const visibleLeft = Math.max(containerLeft, imageLeft);
                const visibleRight = Math.min(containerRight, imageRight);
                const visibleWidth = Math.max(0, visibleRight - visibleLeft);

                if (visibleWidth > maxVisibleArea) {
                    maxVisibleArea = visibleWidth;
                    activeIndex = i;
                }
            });

            setActiveImg(activeIndex);
        }

        imageContainerRef.current.addEventListener("scroll", handleScroll);
        return () => imageContainerRef?.current?.removeEventListener("scroll", handleScroll);

    }, []);

    // Handle drag to scroll
    useEffect(() => {

        const container = imageContainerRef.current;

        const handleMouseDown = (e) => {
            isDragging.current = true;
            startX.current = e.pageX - container.offsetLeft;
            scrollLeft.current = container.scrollLeft;
            container.style.cursor = "grabbing"; // Update cursor style on drag start
            e.preventDefault(); // Prevent text/image selection on drag
        };

        const handleMouseMove = (e) => {
            if (!isDragging.current) return;
            const x = e.pageX - container.offsetLeft;
            const walk = (x - startX.current) * 1.5; // The multiplier controls the scroll speed
            container.scrollLeft = scrollLeft.current - walk;
        };

        const handleMouseUpOrLeave = () => {
            if (isDragging.current) {
                isDragging.current = false;
                container.style.cursor = "grab"; // Revert cursor style
            }
        };

        // Add mouse event listeners for drag functionality
        container.addEventListener("mousedown", handleMouseDown);
        container.addEventListener("mousemove", handleMouseMove);

        // Attach mouseup and mouseleave events to the document to ensure the drag stops anywhere
        document.addEventListener("mouseup", handleMouseUpOrLeave);
        document.addEventListener("mouseleave", handleMouseUpOrLeave);

        return () => {
            // Cleanup event listeners
            container.removeEventListener("mousedown", handleMouseDown);
            container.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUpOrLeave);
            document.removeEventListener("mouseleave", handleMouseUpOrLeave);
        };

    }, []);

    return(
        <>
            <Box
                sx={{
                    p:'4rem 0'
                }}
            >
                <Container maxWidth='xl'>
                    <Box
                        ref={imageContainerRef}
                        sx={{
                            display: "flex",
                            mb: "2rem",
                            overflowX: "scroll", // Allow horizontal scrolling with mouse wheel
                            cursor: isDragging.current ? "grabbing" : "grab", // Change cursor style during drag
                        }}
                    >
                        {Object.values(showcase.images.data).map((image, i) => {
                            //console.log(image)
                            return(
                                <Box
                                    key={image.id}
                                    sx={{
                                        position:'relative',
                                        mr:'1.5rem'
                                    }}
                                >
                                    <img
                                        src={process.env.REACT_APP_BACKEND + image.attributes.url}
                                        alt={image.attributes.alternativeText}
                                        draggable={false}
                                        style={{
                                            height: mobile? '250px' : '500px',
                                        }}
                                    />
                                    <IconButton
                                        onClick={()=>setExpandedImg(i)}
                                        sx={{
                                            position:"absolute",
                                            top:'1rem',
                                            right:'1rem',
                                            background:'rgba(0,0,0,0.5)',
                                            '&:hover':{
                                                background:'rgba(0,0,0,0.75)'
                                            }
                                        }}
                                    >
                                        <ZoomInIcon
                                            fontSize="large"
                                            sx={{
                                                color:'#fff'
                                            }}
                                        />
                                    </IconButton>
                                </Box>
                            );
                        })}
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            mb: "4rem",
                        }}
                    >
                        {Object.values(showcase.images.data).map((image, i) => {
                            return (
                                <Box
                                    key={i}
                                    onClick={() => scrollToImg(i)}
                                    sx={{
                                        mr: "0.5rem",
                                        height: "1rem",
                                        width: "1rem",
                                        borderRadius: "1rem",
                                        border: (theme) => "0.2rem solid " + theme.palette.blue,
                                        backgroundColor: (theme) => (activeImg === i ? theme.palette.blue : "none"),
                                        transition: "background-color 0.25s",
                                        cursor: "pointer",
                                    }}
                                />
                            );
                        })}
                    </Box>
                </Container>
            </Box>
            <Backdrop open={expandedImg!==null}
                sx={{
                    zIndex:99999999
                }}
            >
                <IconButton
                    onClick={()=>setExpandedImg(null)}
                    sx={{
                        position:'absolute',
                        top:'2.5vh',
                        right:'2.5vw',
                        background:'rgba(0,0,0,0.25)',
                        '&:hover':{
                            background:'rgba(0,0,0,0.5)'
                        }
                    }}
                >
                    <CloseIcon
                        fontSize="large"
                        sx={{
                            color:'#fff'
                        }}
                    />
                </IconButton>
                {expandedImg!==null &&
                    <img
                        src={process.env.REACT_APP_BACKEND+showcase.images.data[expandedImg].attributes.url}
                        alt={showcase.images.data[expandedImg].attributes.alternativeText}
                        draggable={false}
                        style={{
                            width:'100%',
                            height:'100%',
                            maxWidth:'95vw',
                            maxHeight:'95vh',
                            objectFit:'contain'
                        }}
                    />
                }
            </Backdrop>
        </>
    );
}

export default SingleShowcaseImages;