import { Box, Container, Fade, Grid, Typography } from "@mui/material";
import { useState } from "react";
import parse from 'html-react-parser';
import { Parallax } from "react-scroll-parallax";

export default function TabbedContent({ content }){

    //console.log(content);

    const [activeTab, setActiveTab]=useState(0);

    return(
        <Box
            className='dm-section'
            data-color='#01153C'
            sx={{
                background:'#fff',
                color:theme=>theme.palette.blue,
                p:'4rem 0'
            }}
        >
            <Container maxWidth='xl'>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h3'
                            sx={{
                                mb:'2rem'
                            }}
                        >
                            {content.heading}
                        </Typography>
                    </Grid>
                    {content.tabs.map((tab, i)=>{
                        return(
                            <Grid item xs={12} md={2}>
                                <Typography
                                    onClick={()=>setActiveTab(i)}
                                    sx={{
                                        fontSize:'2rem',
                                        fontWeight:700,
                                        width:'fit-content',
                                        borderBottom: theme=> activeTab===i? '4px solid '+theme.palette.yellow : 'none',
                                        cursor:'pointer'
                                    }}
                                >
                                    {tab.name}
                                </Typography>
                            </Grid>
                        );
                    })}
                    <Grid item xs={12} container spacing={2}>
                        {content.tabs.map((tab, i)=>{
                            if(activeTab===i){
                                return(
                                    <>
                                        <Fade in={activeTab===i}>
                                            <Grid item xs={12} md={7}
                                                className="html-section"
                                            >
                                                {parse(tab.content)}
                                            </Grid>
                                            
                                        </Fade>
                                        {tab.image.data &&
                                            <Fade in={activeTab===i}>
                                                <Grid item xs={12} md={5}
                                                    sx={{
                                                        display:'flex',
                                                        alignItems:'center',
                                                        justifyContent:'center'
                                                    }}
                                                >
                                                    <Parallax
                                                        speed={5}
                                                    >
                                                        <img
                                                            src={process.env.REACT_APP_BACKEND+tab.image.data.attributes.url}
                                                            alt={tab.image.data.attributes.alternativeText}
                                                            draggable={false}
                                                            style={{
                                                                width:'100%',
                                                                maxWidth:'300px',
                                                            }}
                                                        />
                                                    </Parallax>
                                                </Grid>
                                            </Fade>
                                        }
                                    </>
                                );
                            }
                        })}
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}