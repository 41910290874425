import { Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import parse from 'html-react-parser';

function SingleServiceHeader({ service }) {

    const mobile = useMediaQuery(theme => theme.breakpoints.down('md'));

    return (
        <Box
            sx={{
                p:'8rem 0',
                minHeight:'calc(100vh - 16rem)',
                display:'flex',
                alignItems:'center',
                backgroundColor: theme => theme.palette.blue,
            }}
        >
            <Container maxWidth='xl'>
                <Box
                    sx={{
                        color: theme => theme.palette.blue
                    }}
                >
                    <Grid container spacing={2} alignItems='center'>
                        <Grid item xs={12} md={5}
                            sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                            }}
                        >
                            <Box
                                sx={{
                                    height: 'fit-content'
                                }}
                            >
                                <img
                                    src={process.env.REACT_APP_BACKEND + service.thumbnail.data.attributes.url}
                                    alt={service.thumbnail.data.attributes.alternativeText}
                                    width='100%'
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <Box
                                sx={{
                                    m: mobile ? 0 : '-2rem 4rem 0 4rem',
                                    color:'#fff'
                                }}
                            >
                                <Box
                                    className='html-section'
                                    sx={{
                                        '& h1':{
                                            margin:0,
                                            fontSize:{
                                                xs:'2.6rem',
                                                md:'3.2rem'
                                            }
                                        }
                                    }}
                                >
                                    {parse(service.intro)}
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    )
}

export default SingleServiceHeader;