import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import AnimatedText from "../reusable/AnimatedText";

export default function SingleServiceExpertise({ service }){

    //console.log(service);

    const mobile=useMediaQuery(theme=>theme.breakpoints.down('md'));

    return(
        <>
            {(service.expertise_heading && service.expertise_text) &&
                <Box
                    className='dm-section'
                    data-color='#fff'
                    sx={{
                        p:'4rem 0',
                        background:theme=>theme.palette.yellow
                    }}
                >
                    <Container maxWidth='lg'>
                        <Typography variant='h5'
                            sx={{
                                color:'#fff',
                                mb:'-2rem'
                            }}
                        >
                            {service.expertise_heading}
                        </Typography>
                        <AnimatedText 
                            text={service.expertise_text}
                            variant={mobile? 'h5' : 'h3'}
                            component='p'
                            sx={{
                                color:theme=>theme.palette.blue
                            }}
                        />
                    </Container>
                </Box>
            }  
        </>
    );
}