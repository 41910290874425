import { Box, Typography } from '@mui/material';
import React from 'react';

function SingleShowcaseHero({showcase}){

    return(
        <Box
            sx={{
                position:'relative',
                height:window.innerHeight,
                backgroundImage:'url('+process.env.REACT_APP_BACKEND+showcase?.hero_image.data?.attributes.url+')',
                backgroundSize:'cover',
                backgroundPosition:'center'
            }}
        >
            <Box
                sx={{
                    position:'absolute',
                    width:'100%',
                    height:'100%',
                    background:'linear-gradient(180deg, rgba(0,0,0,0.5) 0%, transparent 50%, rgba(0,0,0,0.5) 100%)',
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'flex-end',
                    alignItems:'center',
                    color:'#fff',
                    textAlign:'center',
                }}
            >
                <Typography variant='h3' component='h1'>
                    {showcase.title}
                </Typography>
                <Box
                    sx={{
                        display:'flex',
                        justifyContent:'center',
                        flexWrap:'wrap',
                        m:'1rem 1rem 4rem 0'
                    }}
                >
                    {Object.values(showcase.skills.data).map((skill, i)=>{
                        return(
                            <Typography
                                key={i}
                                sx={{
                                    mr:'0.75rem',
                                    color:theme=>theme.palette.yellow,
                                    textTransform:'uppercase',
                                    fontWeight:'bold'
                                }}
                            >
                                {skill.attributes.name}.
                            </Typography>
                        )
                    })}
                </Box>
            </Box>
        </Box>
    )
}

export default SingleShowcaseHero;