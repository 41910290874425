import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import ShowcasePreview from "./ShowcasePreview";

function ShowcasesList({ showcases, singleServicePage=false }){

    const mobile=useMediaQuery(theme=>theme.breakpoints.down('md'));

    const[display,setDisplay]=useState(false);
    useEffect(() => {
        setTimeout(()=>{
            setDisplay(true);
        },200);
    },[]);

    return(
        <Box
            className='dm-section'
            data-color='#01153C'
            sx={{
                position:'relative',
                background:'#fff',
                p:'4rem 0'
            }}
        >
            <Box
                className='dm-section'
                data-color='#FFF'
                sx={{
                    position:'absolute',
                    top:0,
                    background:theme=>theme.palette.blue,
                    height:{
                        xs: singleServicePage? '40vh' : '20vh',
                        sm: singleServicePage? '30vh' : '15vh',
                        md: singleServicePage? '66vh' : '33vh'
                    },
                    width:'100%'
                }}
            />
            <Container maxWidth='xl'>
                {singleServicePage &&
                    <Typography
                        sx={{
                            transform:'translateZ(1px)',
                            fontSize:{
                                xs:'3rem',
                                md:'5rem'
                            },
                            lineHeight:{
                                xs:'3rem',
                                md:'5rem'
                            },
                            fontWeight:'bold',
                            color:'#fff',
                        }}
                    >
                        Related<br/>Projects
                    </Typography>
                }
                <ResponsiveMasonry
                    columnsCountBreakPoints={{0: 1, 600: 2}}
                >
                    <Masonry>
                        {showcases && Object.values(showcases).map((showcase, i)=>{
                            return(
                                <Box
                                    key={i}
                                    sx={{
                                        mt: mobile? '2rem' : i===0? '16.6vh' : 0,
                                        translate: display? 0 : '0 25vh',
                                        opacity: display? 1 : 0,
                                        transition: 'translate 0.5s, opacity 0.5s',
                                        transitionDelay: `${i * 0.3}s`
                                    }}
                                >
                                    <ShowcasePreview showcase={showcase} key={i}/>
                                </Box>
                            );
                        })}
                    </Masonry>
                </ResponsiveMasonry>
            </Container>
        </Box>
    );
}

export default ShowcasesList;